/* eslint-disable no-bitwise */

import _ from "lodash";
import axios from "axios";
import queryString from "query-string";
import sanitizeHtml from "sanitize-html";
import objectHash from "object-hash";
import dateFns from "date-fns";
import { isValidPhoneNumber } from "libphonenumber-js";

import {
    WORLDWIDE_REGION,
    DATE_FORMAT,
    DATETIME_FORMAT,
    DATE_FORMAT_API,
    EVENT_LIST,
    SPLIT_LIST,
    MEET_TYPE_CHOICES,
    RANK_TYPE_OPTIONS,
    AGE_GROUP_OPTIONS,
    STROKE_LABELS,
    GENDER_LABELS,
    GENDER_LABELS_ALT,
    VARSITY_LABELS,
    COUNTRY_LABELS,
    HS_CLASS_LABELS,
    EVENTROUND_LABELS,
    ALL_COURSE_SHORT_LABELS,
    EVENTROUND_SWIMOFF,
    SWIMOFF_EVENTROUND,
    DEFAULT_EVENTAGE,
    START_ALTERNATES_FROM,
    ENTRIES_ORDER_SESSION,
    ENTRIES_ORDER_NUMBER,
    ENTRIES_ORDER_EVENT,
    ENTRIES_ORDER_NUMERIC_ID,
    ENTRIES_ORDER_SEEDTIME,
    ENTRIES_ORDER_EVENTTIME,
    ENTRIES_ORDER_PLACE,
    ENTRIES_ORDER_SWIMMER,
    ENTRIES_ORDER_TEAM,
    TIMECODE_STAGES,
    TIMECODE_MAP,
    INVERTED_TIMECODE_MAP,
    MEET_MANAGE_QUALIFYING_START_LOCAL_KEY,
    MEET_COLLABORATE_QUALIFYING_START_LOCAL_KEY,
    MEET_MANAGE_QUALIFYING_END_LOCAL_KEY,
    MEET_COLLABORATE_QUALIFYING_END_LOCAL_KEY,
    DQ_CODES,
    MISCELLANEOUS_DQ_CODES,
    EVENT_TIMES_LAYOUTS,
    ALLOW_EXCEED_WARNING,
    DEFAULT_TIMES_AGE_GROUP,
    GENDER_OPTIONS,
    C_GENDER_OPTION_ALT,
    X_GENDER_OPTION,
    DEFAULT_SCOREBOARD_SCENES,
    ALTERNATIVE_EVENT_ID_OPTIONS_MAP,
    FULL_STROKE_LABELS,
    DISTANCE_CHANGE_MAP,
} from "./constants";

export const initAxios = () => {
    axios.defaults.xsrfCookieName = "csrftoken";
    axios.defaults.xsrfHeaderName = "X-CSRFToken";
    axios.defaults.paramsSerializer = queryString.stringify;
};

export const getCookieByName = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);

    if (parts.length === 2) return parts.pop().split(";").shift();

    return null;
};

export const decimalToSwim = (decimal) => {
    if (!decimal) return "NT";

    const number = Number(decimal);
    if (Number.isNaN(number)) return "NT";

    let minutes = number / 60;
    minutes = Math.floor(minutes);

    let seconds = number % 60;
    seconds = Math.floor(seconds);

    let milliseconds = (number - minutes * 60 - seconds) * 100;
    milliseconds = Math.round(milliseconds);

    let result = "";
    if (minutes) {
        result += `${minutes}:`;
        result += `00${seconds}`.substr(-2); // if minutes => add leading zeros
    } else {
        result += seconds;
    }
    result += ".";
    result += `00${milliseconds}`.substr(-2); // add leading zeros
    return result;
};

export const swimToDecimal = (time) => {
    if (!time) return null;
    let decimal;

    if (time.indexOf(":") !== -1) {
        let minutes = time.substr(0, time.indexOf(":"));
        let seconds = time.substr(time.indexOf(":") + 1);
        minutes = Number(minutes) * 60;
        seconds = Number(seconds);
        decimal = Number(minutes + seconds);
    } else {
        decimal = Number(time);
    }

    if (Number.isNaN(decimal)) return decimal;
    return parseFloat(decimal.toFixed(2));
};

export const escapeRegExp = (str) => {
    const specials = [
        "-",
        "[",
        "]",
        "/",
        "{",
        "}",
        "(",
        ")",
        "*",
        "+",
        "?",
        ".",
        "\\",
        "^",
        "$",
        "|",
    ];
    const regex = RegExp(`[${specials.join("\\")}]`, "g");
    return str.replace(regex, "\\$&");
};

export const filterByName = (searchText, dataset, property = "name") => {
    const searchSet = searchText.toLowerCase().trim().split(" ");

    return dataset.filter((item) => {
        let name;

        name = _.isFunction(property) ? property(item) : item[property];

        name = name.toLowerCase();

        return searchSet.every((search) => name.includes(search));
    }, []);
};

export const getSiteName = () => {
    const { hostname } = window.location;
    if (hostname.includes("collegeswimming.com")) return "CollegeSwimming";
    if (hostname.includes("swimcloud.com")) return "Swimcloud";
    return "Swimcloud";
};

export const getHostName = ({ showProtocol = true } = {}) => {
    const { protocol } = window.location;
    const host = window.location.hostname;
    return `${showProtocol ? `${protocol}//` : ""}${host}`;
};

export const getMeetTypes = (orgcode) => {
    if (!orgcode) return MEET_TYPE_CHOICES;

    return MEET_TYPE_CHOICES.filter((choice) =>
        choice.orgcodes.includes(orgcode),
    );
};

export const getRankTypeByAbbr = (abbr) => {
    const rankType = RANK_TYPE_OPTIONS.find((o) => o.key === abbr);
    return rankType ? rankType.value : null;
};

// if method is changed here => change it in cs_utils
export const getSeasonIdFromYear = (year) => year - 1996;

// if method is changed here => change it in cs_utils
export const getYearFromSeasonId = (seasonId) => seasonId + 1996;

// if method is changed here => change it in cs_utils
export const getSeasonName = (seasonId) => {
    const startYear = getYearFromSeasonId(seasonId);
    const endYear = startYear + 1;
    return `${startYear}-${endYear}`;
};

// if method is changed here => change it in cs_utils
export const getSeasonStartValue = (country, orgcode) => {
    if (["SAF", "ZAF", "RSA"].includes(country)) {
        // South Africa
        return [5, 1];
    }

    if (country === "USA" && orgcode === 9) {
        // month, day
        return [8, 1];
    }

    // month, day
    return [9, 1];
};

// if method is changed here => change it in cs_utils
export const getSeasonStart = (
    country = null,
    orgcode = null,
    team = null,
    meet = null,
    region = null,
) => {
    if (team && team.country && team.orgcode) {
        return getSeasonStartValue(team.country, team.orgcode);
    }

    if (meet && meet.country && meet.orgcode) {
        return getSeasonStartValue(meet.country, meet.orgcode);
    }

    if (region && region.country && region.orgcode) {
        return getSeasonStartValue(region.country, region.orgcode);
    }

    return getSeasonStartValue(country, orgcode);
};

// if method is changed here => change it in cs_utils
export const getTodaySeasonId = () => {
    const [month, day] = getSeasonStart();

    const today = new Date();
    const todayYear = today.getFullYear();
    const todayMonth = today.getMonth() + 1;
    const todayDate = today.getDate();

    if (todayMonth >= month && todayDate >= day) {
        return getSeasonIdFromYear(todayYear);
    }

    return getSeasonIdFromYear(todayYear - 1);
};

export const getMeetSeason = (startDate) => {
    if (!startDate) return null;

    const meetStartDate = new Date(startDate);
    const meetYear = meetStartDate.getFullYear();
    const meetMonth = meetStartDate.getMonth() + 1;
    const meetDay = meetStartDate.getDate();
    const [month, day] = getSeasonStart();

    if (meetMonth >= month && meetDay >= day) {
        return getSeasonIdFromYear(meetYear);
    }

    return getSeasonIdFromYear(meetYear - 1);
};

export const getMeetAgeUpDate = (dateOfSwim, meet) => {
    let meetStartDate = new Date();
    let meetStartDateYear = meetStartDate.getFullYear();

    if (meet.startdate) {
        if (meet.startdate instanceof Date) {
            meetStartDate = meet.startdate;
            meetStartDateYear = meet.startdate.getFullYear();
        } else {
            meetStartDate = new Date(meet.startdate);
            meetStartDateYear = meetStartDate.getFullYear();
        }
    }

    if (meet.ageup_strategy) {
        if (meet.ageup_strategy === "dos") {
            // meetStartDate is closest to dateofswim
            return dateOfSwim || meetStartDate;
        }
        if (meet.ageup_strategy === "year") {
            const fixedDate = new Date();
            fixedDate.setFullYear(meetStartDateYear);
            fixedDate.setMonth(11);
            fixedDate.setDate(31);

            return fixedDate;
        }
        if (meet.ageup_strategy === "midyear") {
            const fixedDate = new Date();
            fixedDate.setFullYear(meetStartDateYear);
            fixedDate.setMonth(5);
            fixedDate.setDate(1);

            return fixedDate;
        }
        if (meet.ageup_strategy.startsWith("date_")) {
            const ageUpStrategyDate = meet.ageup_strategy.split("_")[1];

            const fixedDate = new Date();
            fixedDate.setFullYear(Number(ageUpStrategyDate[1]));
            fixedDate.setMonth(Number(ageUpStrategyDate[2] - 1));
            fixedDate.setDate(Number(ageUpStrategyDate[3]));

            return fixedDate;
        }
    }

    return meetStartDate;
};

export const getActionsFromQuery = ({ config, search }) => {
    /* 	options: <array>
		func: <method>
		type: <oneOf: "string" | "boolean" | "number">
		forbidNull: <boolean>
	*/
    const parsed = queryString.parse(search || window.location.search);

    const actions = _.toPairs(parsed).map(([key, initial]) => {
        const rules = config[key];

        if (!rules) return null;

        const { validate, func, options, type, forbidNull } = rules;

        let value = initial;

        if (validate && !validate(value)) return null;

        if (type === "string") value = value ? String(value) : "";

        if (type === "number") {
            value = Number(value);

            if (Number.isNaN(value)) return null;
        }

        if (type === "boolean")
            try {
                value = Boolean(JSON.parse(String(value).toLowerCase()));
            } catch (error) {
                value = false;
            }

        if (forbidNull && !value) return null;

        if (options && value && !options.includes(value)) return null;

        return func(value);
    });

    return actions.filter((a) => !!a);
};

export const getRelaySplitId = (eventId) => {
    const prefix = "split_";

    if (eventId && !eventId.startsWith(prefix)) return null;

    return eventId.substr(prefix.length);
};

export const simulateServerErrorResponse = ({ errorMap }) => {
    return {
        response: {
            headers: { "content-type": "application/json" },
            data: errorMap,
        },
    };
};

export const flattenObject = (obj, prefix = "") =>
    _.toPairs(obj).reduce((reduced, [key, value]) => {
        if (typeof value === "object" && value !== null) {
            const nested = flattenObject(value, `${(prefix || "") + key}.`);
            return { ...reduced, ...nested };
        }
        return { ...reduced, [`${prefix}${key}`]: value };
    }, {});

export const humanizeServerError = (error) => {
    let msg = "Something went wrong";
    const { response } = error;
    if (response && response.data) {
        if (response.headers["content-type"] === "application/json") {
            const { data } = response;
            if (_.isString(data)) msg = data;
            else msg = _.values(flattenObject(data)).join(", ");
        }
    }
    return msg;
};

export const getEventCourseName = (eventcourse) =>
    ALL_COURSE_SHORT_LABELS[eventcourse] || eventcourse;

export const getEventPropsFromId = (_id) => {
    /*
		1200Y => { eventstroke: "1", eventdistance: 200, eventcourse: "Y" };
	*/
    let id = _id;
    if (!id) return null;
    id = _.toUpper(id);

    const eventstroke = id[0];
    let eventcourse;
    let eventdistance;

    if (eventstroke === "H") {
        eventdistance = id[1];
        eventcourse = id.substring(2);
    } else {
        const lastChar = id[id.length - 1];
        if (_.isNaN(_.toNumber(lastChar))) {
            eventdistance = id.substring(1, id.length - 1);
            eventcourse = lastChar;
        } else {
            eventdistance = id.substring(1);
            eventcourse = "";
        }
    }
    eventdistance = _.toNumber(eventdistance);
    return {
        eventstroke,
        eventdistance,
        eventcourse,
    };
};

export const getEventNameById = (
    eventId,
    { showCourse = false, isSplit = false, useFullStrokeName = false } = {},
) => {
    if (isSplit) {
        const splitMatch = SPLIT_LIST.find((x) => x.key === eventId);
        if (splitMatch) return splitMatch.value;
    }

    const props = getEventPropsFromId(eventId);

    if (!props) return null;
    const { eventstroke, eventdistance, eventcourse } = props;
    // parse stroke and distance
    let name;

    if (useFullStrokeName) {
        const strokeLabel = FULL_STROKE_LABELS[eventstroke] || eventstroke;
        name = `${eventdistance} ${strokeLabel}`;
    } else {
        const match = EVENT_LIST.find(
            (x) => x.key === `${eventstroke}${eventdistance}`,
        );
        if (match) name = match.value;
        else {
            const strokeLabel = STROKE_LABELS[eventstroke] || eventstroke;
            name = `${eventdistance} ${strokeLabel}`;
        }
    }
    // parse course
    if (showCourse && eventcourse) {
        let courseLabel;
        if (eventstroke === "H") {
            const dives = parseInt(eventcourse, 16);
            courseLabel = `(${dives} dives)`;
        } else {
            courseLabel = getEventCourseName(eventcourse);
        }
        if (courseLabel) name += ` ${courseLabel}`;
    }

    return name;
};

export const parseEventId = ({ eventId }) => {
    if (!eventId) return ["", "", ""];

    const stroke = eventId.slice(0, 1);
    const distance = eventId.slice(1, eventId.length - 1);
    const course = eventId.slice(eventId.length - 1, eventId.length);

    return [stroke, distance, course];
};

export const getLegsEvents = ({ meetEvent, numlegs = 4 }) => {
    const { eventstroke, eventdistance, eventcourse, eventgender } = meetEvent;

    const avgdistance = Math.floor(eventdistance / numlegs);
    const legstrokes =
        eventstroke === "6" ? ["1", "1", "1", "1"] : ["2", "3", "4", "1"];

    return _.range(0, numlegs).map((i) => ({
        legposition: i + 1,
        eventdistance: avgdistance,
        eventstroke: legstrokes[i] || "1",
        eventcourse,
        eventgender,
    }));
};

export const getLegEventDisplay = (legEvent) => {
    const { legposition, eventstroke, eventdistance } = legEvent;
    const eventId = `${eventstroke}${eventdistance}`;
    const eventName = getEventNameById(eventId);
    return `Leg ${legposition} (${eventName})`;
};

const truncateExtra = (data, sizeInBytes = 0) => {
    const sizeInChars = Math.floor(sizeInBytes / 2); // each character is 2 bytes

    const dataString = _.isString(data) ? data : JSON.stringify(data);

    return dataString.slice(0, sizeInChars);
};

const captureAxiosError = (error) => {
    const { name, response, config } = error;

    const status = response && response.status;

    // skip error 0 (Request Aborted, Network Error)
    if (!status) return;

    // skip error 429 (Too Many Requests)
    if (status === 429) return;

    // skip error 469 (Content Pending)
    if (status === 469) return;

    // skip error 5xx (Server Error)
    if (status >= 500 && status < 600) return;

    const url =
        config && config.url && String(config.url).replace(/[0-9]+/, "<>");

    const message = [name, status, url].filter(Boolean).join(" ");

    const { Sentry } = window;
    if (Sentry)
        Sentry.captureMessage(message, (scope) => {
            scope.setTag("axios", true);
            scope.setTag("captured", true);

            scope.setFingerprint([message]);

            // make sure sentry total payload is less than 200KB

            if (config && config.params)
                scope.setExtra(
                    "configParams",
                    truncateExtra(config.params, 30 * 1000), // 30KB
                );

            if (config && config.data)
                scope.setExtra(
                    "configData",
                    truncateExtra(config.data, 30 * 1000), // 30KB
                );

            if (response && response.data)
                scope.setExtra(
                    "responseData",
                    truncateExtra(response.data, 30 * 1000), // 30KB
                );

            return scope;
        });
};

export const captureError = (error) => {
    // show in console
    console.error(error.message);

    if (error.isAxiosError) {
        captureAxiosError(error);
        return;
    }

    // send to Sentry
    const { Sentry } = window;
    if (Sentry)
        Sentry.captureException(error, (scope) => {
            scope.setTag("captured", true);

            return scope;
        });
};

export const captureMessage = (message, details) => {
    // show in console
    console.warn(message);

    if (details) console.warn(details);

    // send to Sentry
    const { Sentry } = window;
    if (Sentry)
        Sentry.captureMessage(message, (scope) => {
            scope.setTag("captured", true);

            // make sure sentry total payload is less than 200KB

            if (details)
                scope.setExtra("details", truncateExtra(details, 50 * 1000)); // 50KB
        });
};

export const fetchRegion = async (choiceValue) => {
    if (choiceValue === "global") return WORLDWIDE_REGION;
    try {
        const response = await axios.get("/api/regions/get/", {
            params: { choice_value: choiceValue },
        });
        const region = response.data;
        return region;
    } catch (error) {
        captureError(error);
    }
    return null;
};

const COMPACT_REGION_TREE_CACHE_KEY = "__regionTreeCacheCompact";
const REGION_TREE_CACHE_KEY = "__regionTreeCache";
const regionTreeCache = {};

export const fetchRegionTree = async ({ includeGlobal, compact } = {}) => {
    const _parseTree = (regions, parent = null) => {
        if (regions && regions.length > 0)
            regions.forEach((_region) => {
                // assign parent property for each region in tree
                const region = _region;
                region.parent = parent;
                const { subregions } = region;
                _parseTree(subregions, region);
            });
    };

    let regionTreeData = [];

    if (compact) {
        regionTreeData = regionTreeCache[COMPACT_REGION_TREE_CACHE_KEY] || [];

        if (!regionTreeData.length) {
            try {
                const response = await axios.get("/api/regions/tree/", {
                    params: { compact },
                });
                const { data } = response;

                regionTreeData = data;
                regionTreeCache[COMPACT_REGION_TREE_CACHE_KEY] = data;
            } catch (error) {
                captureError(error);
            }
        }
    } else {
        regionTreeData = regionTreeCache[REGION_TREE_CACHE_KEY] || [];

        if (!regionTreeData.length) {
            try {
                const response = await axios.get("/api/regions/tree/");
                const { data } = response;

                regionTreeData = data;
                regionTreeCache[REGION_TREE_CACHE_KEY] = data;
            } catch (error) {
                captureError(error);
            }
        }
    }

    // build tree
    const tree = includeGlobal
        ? [{ ...WORLDWIDE_REGION, subregions: regionTreeData }]
        : regionTreeData;

    _parseTree(tree);
    return tree;
};

const seasonsCache = {};

export const fetchSeasons = async ({
    count = 10,
    fromNext = false,
    teamCountry = null,
    teamOrgcode = null,
} = {}) => {
    const params = { count, from_next: fromNext };

    if (teamCountry) {
        params.country = teamCountry;
    }
    if (teamOrgcode) {
        params.orgcode = teamOrgcode;
    }

    const key = objectHash({ name: "seasons", params });
    let seasons = seasonsCache[key] || [];
    if (!seasons.length)
        try {
            const response = await axios.get(`/api/seasonchoices/`, { params });
            const { data } = response;
            seasons = data;
            seasonsCache[key] = data;
        } catch (error) {
            captureError(error);
        }
    return seasons;
};

export const flattenRegionTree = (tree) => {
    const _flatChoices = (regions) =>
        regions
            ? regions.map((region) => {
                  const { subregions } = region;
                  return [region, _flatChoices(subregions)];
              })
            : [];
    return _.flattenDeep(_flatChoices(tree));
};

export const getRegionBreadcrumbs = ({ region, param }) => {
    if (!region) return [];
    const _getRegionBreadcrumbs = (item) => {
        const { parent } = item;
        const parents = parent ? _getRegionBreadcrumbs(parent) : [];
        return [item, ...parents];
    };
    let breadcrumbs = _getRegionBreadcrumbs(region).reverse();
    if (param) breadcrumbs = breadcrumbs.map((x) => x[param]);
    return breadcrumbs;
};

export const prettyDate = (date) => dateFns.format(date, DATE_FORMAT);

export const prettyDateTime = (date) => dateFns.format(date, DATETIME_FORMAT);

export const apiDate = (date) => dateFns.format(date, DATE_FORMAT_API);

export const prettyDateRange = (_start, _end) => {
    /*
		javascript implementation of
		pretty_date_range at cs.apps.utils.cs_utils
	*/
    const start = dateFns.isDate(_start) ? _start : dateFns.parse(_start);
    const end = dateFns.isDate(_end) ? _end : dateFns.parse(_end);
    if (
        start.getDay() === end.getDay() &&
        start.getMonth() === end.getMonth() &&
        start.getFullYear() === end.getFullYear()
    )
        // start = Jan 1, 2017
        // end = Jan 1, 2017
        // output = Jan 1, 2017
        return prettyDate(start);
    if (
        start.getMonth() === end.getMonth() &&
        start.getFullYear() === end.getFullYear()
    )
        // start = Jan 1, 2017
        // end = Jan 5, 2017
        // output = Jan 1–5, 2017
        return `${dateFns.format(start, "MMM D")}\u2013${dateFns.format(
            end,
            "D, YYYY",
        )}`;
    if (start.getFullYear() === end.getFullYear())
        // start = Jan 1, 2017
        // end = Feb 5, 2017
        // output = Jan 1–Feb 5, 2017
        return `${dateFns.format(start, "MMM D")}\u2013${dateFns.format(
            end,
            "MMM D, YYYY",
        )}`;
    // start = Dec 28, 2016
    // end = Jan 1, 2017
    // output = Dec 28, 2016–Jan 1, 2017
    return `${prettyDate(start)}\u2013${prettyDate(end)}`;
};

export const getEventStrokeName = (stroke) => STROKE_LABELS[stroke] || stroke;

export const getEventRoundName = (round) => EVENTROUND_LABELS[round] || round;

export const getEventGenderName = (gender) => GENDER_LABELS[gender] || gender;

export const getEventGenderNameAlt = (gender) =>
    GENDER_LABELS_ALT[gender] || gender;

export const getVarsityName = (varsity) => VARSITY_LABELS[varsity] || varsity;

export const getHsClassName = (hsclass) => HS_CLASS_LABELS[hsclass] || hsclass;

export const padLeadingZero = (num) => {
    return num.toString().length < 2 ? `0${num}` : num;
};

const AGHEX_DIGITS = "ABCDEFGHIJKLMPQRSTWXYZ";
const AGHEX_BASE = AGHEX_DIGITS.length;

// exists on the backend too
export const toAgeGroupHex = (dec) => {
    const quotient = Math.floor(dec / AGHEX_BASE);
    const remainder = dec % AGHEX_BASE;

    if (!quotient) return AGHEX_DIGITS[remainder];
    return toAgeGroupHex(quotient) + AGHEX_DIGITS[remainder];
};

// exists on the backend too
export const fromAgeGroupHex = (hex) => {
    let ret = 0;

    hex.split("").forEach((letter, index) => {
        const value = AGHEX_DIGITS.indexOf(letter);
        const power = hex.length - (index + 1);
        ret += value * AGHEX_BASE ** power;
    });

    return ret;
};

// exists on the backend too
export const minAgeGroupHex = (minAge) => {
    if (!minAge || minAge < 1 || minAge === "UN") return "UN";
    if (minAge <= 99) return minAge;
    if (minAge <= 483) return toAgeGroupHex(minAge);

    return null;
};

// exists on the backend too
export const maxAgeGroupHex = (maxAge) => {
    if (!maxAge || maxAge > 483 || maxAge === "OV") return "OV";
    if (maxAge <= 99) return `${maxAge}`;

    return toAgeGroupHex(maxAge);
};

// exists on the backend too
export const minAgeGroupHexToInt = (minAgeGroupHexValue) => {
    if (!minAgeGroupHexValue || minAgeGroupHexValue === "UN") return null;

    const age = Number(minAgeGroupHexValue);

    if (_.isNaN(age)) return fromAgeGroupHex(minAgeGroupHexValue);
    if (typeof age === "number") return age;

    return null;
};

// exists on the backend too
export const maxAgeGroupHexToInt = (maxAgeGroupHexValue) => {
    if (!maxAgeGroupHexValue || maxAgeGroupHexValue === "OV") return null;

    const age = Number(maxAgeGroupHexValue);

    if (_.isNaN(age)) return fromAgeGroupHex(maxAgeGroupHexValue);
    if (typeof age === "number") return age;

    return null;
};

export const parseEventAge = (eventage) => {
    if (!eventage) return null;
    // find a match in predefined list
    const match = AGE_GROUP_OPTIONS.find((x) => x.eventage === eventage);
    if (match) return match;
    // parse
    if (eventage.length !== 4) return null;
    let min_age = minAgeGroupHexToInt(eventage.substring(0, 2));
    if (!_.isFinite(min_age)) min_age = null;
    let max_age = maxAgeGroupHexToInt(eventage.substring(2, 4));
    if (!_.isFinite(max_age)) max_age = null;
    let label;
    let abbr;
    if (min_age === null && max_age === null) {
        label = "Open";
        abbr = "Open";
    } else if (min_age === null) {
        label = `${max_age} and under`;
        abbr = `${max_age}-`;
    } else if (max_age === null) {
        label = `${min_age} and over`;
        abbr = `${min_age}+`;
    } else {
        label = `${min_age} - ${max_age}`;
        abbr = `${min_age}-${max_age}`;
    }
    return {
        min_age,
        max_age,
        eventage,
        label,
        abbr,
    };
};

export const getEventAgeName = ({ eventage, abbr = false }) => {
    const ageGroup = parseEventAge(eventage);
    if (ageGroup) return abbr ? ageGroup.abbr : ageGroup.label;
    return eventage;
};

export const getEventAge = (ageGroup) => {
    if (!ageGroup) return "";

    let minAge = ageGroup.min_age || null;
    let maxAge = ageGroup.max_age || null;

    if (ageGroup.min_age === null) {
        minAge = "UN";
    }
    if (ageGroup.max_age === null) {
        maxAge = "OV";
    }
    if (ageGroup.min_age < 10 && ageGroup.min_age > 0) {
        minAge = padLeadingZero(ageGroup.min_age);
    }
    if (ageGroup.max_age < 10 && ageGroup.max_age > 0) {
        maxAge = padLeadingZero(ageGroup.max_age);
    }

    return `${minAgeGroupHex(minAge)}${maxAgeGroupHex(maxAge)}`;
};

export const getCountryName = (country) => COUNTRY_LABELS[country] || country;

/* related to FromToLink component */
export const generateFromToURL = ({ to, from }) => {
    const url = from || window.location.href;
    const params = queryString.stringify({
        from_url: url.startsWith(window.location.origin)
            ? url.replace(window.location.origin, "")
            : "",
    });
    return `${to}?${params}`;
};

export const orderSplashes = ({
    splashes,
    time = "eventtime",
    meetEvent = {},
}) => {
    if (time === "eventtime") {
        const { isScratchOpen } = meetEvent || {};

        return _.orderBy(splashes, [
            (x) => x.isDiving,
            (x) =>
                isScratchOpen && x.timecode === 5
                    ? 0
                    : (x.timecode || 0) * 1000,
            // (x) => !!x.timecode,
            // (x) => (isScratchOpen && x.timecode === 5 ? 0 : x.timecode * 1000),
            (x) => x.exhibition,
            (x) => !x[time],
            (x) => (x[time] ? Number(x[time]) : 0) * (x.isDiving ? -1 : 1),
        ]);
    }

    return _.orderBy(splashes, [
        (x) => x.isDiving,
        (x) => !x[time],
        (x) => (x[time] ? Number(x[time]) : 0) * (x.isDiving ? -1 : 1),
    ]);
};

export const getLegsAggregateTotal = (legs, param = "eventtime") => {
    const times = legs.map((leg) => leg[param] && _.toNumber(leg[param]));
    return times.every(Boolean) ? _.sum(times).toFixed(2) : null;
};

export const base64ToBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i += 1) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
};

// generate noOptionsMessage for react-select
export const getNoOptionsMessage =
    (noQuery = "Search...", noResults = "No results found") =>
    ({ inputValue }) =>
        !inputValue ? noQuery : noResults;

export const splashMatchGender = ({ splash, gender }) =>
    splash.eventgender.toLowerCase() === gender.toLowerCase() ||
    ["C", "X", ""].includes(splash.eventgender);

export const splashMatchEvent = ({ splash, meetEvent }) =>
    splash.eventnumber === meetEvent.eventnumber &&
    splash.eventstroke === meetEvent.eventstroke &&
    splash.eventdistance === meetEvent.eventdistance &&
    splash.eventgender === meetEvent.eventgender &&
    splash.numlegs === meetEvent.numlegs &&
    splash.eventround === meetEvent.eventround;

// cartesian product
// product([1, 2], [3, 4]) => [[1, 3], [1, 4], [2, 3], [2, 4]]
export const product = (...sets) =>
    sets.length
        ? sets.reduce(
              (acc, set) =>
                  _.flatten(acc.map((x) => set.map((y) => [...x, y]))),
              [[]],
          )
        : [];

export const getLoginURL = ({ next = null } = {}) =>
    `/login?${queryString.stringify({ next: next || window.location.href })}`;

export const htmlHasRealContent = (html) => {
    const plain = sanitizeHtml(html, { allowedTags: [] });
    return /\S/.test(plain);
};

let TIMEZONES_CACHE;
export const fetchTimezones = async () => {
    if (!TIMEZONES_CACHE)
        try {
            const response = await axios.get("/api/timezones/");
            TIMEZONES_CACHE = response.data;
        } catch (error) {
            captureError(error);
        }
    return TIMEZONES_CACHE || [];
};

export const getDebugSetting = (key) => {
    const element = document.getElementById("debug-settings");
    return element ? element.dataset[key] : null;
};

export const getTodayDate = () => {
    const debugTodayDate = getDebugSetting("debugTodayDate");

    if (debugTodayDate && dateFns.isValid(dateFns.parse(debugTodayDate)))
        return dateFns.parse(debugTodayDate);

    return new Date();
};

// implementation of bluebirdjs mapSeries
// http://bluebirdjs.com/docs/api/promise.mapseries.html
export const promiseMapSeries = async (input, mapper) => {
    const responses = [];

    await input.reduce(async (prevPromise, inputItem) => {
        await prevPromise;
        responses.push(await mapper(inputItem));
    }, Promise.resolve());

    return responses;
};

export const capitalizeFirstLetter = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
};

export const getTagName = (tagName) => {
    if (!tagName) return "";

    let humanizedName = "";
    const splittedNameArray = tagName.split("-");

    splittedNameArray.forEach((nameSplit, index) => {
        if (!nameSplit) return;
        if (index === 0) {
            humanizedName += `${capitalizeFirstLetter(nameSplit)} `;
            return;
        }
        humanizedName += `${nameSplit} `;
    });

    return humanizedName;
};

export const getSwimTime = (time) => {
    if (!time) return null;

    const timeString = String(time);

    if (!timeString.includes(".")) return time;

    const timeArray = timeString.split(".");
    const timeSeconds = Number(timeArray[0]);
    const milliseconds = Number(timeArray[1]);
    const minutes = Math.floor(timeSeconds / 60);
    const seconds = timeSeconds - minutes * 60;

    return `${padLeadingZero(minutes)}:${padLeadingZero(
        seconds,
    )}:${padLeadingZero(milliseconds)}`;
};

export const convertToSlug = (text) => {
    return text
        .trim()
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/[-]+/g, "-")
        .replace(/[^\w-]+/g, "");
};

export const activeGradhs = () => {
    const today = new Date();
    const todayMonth = today.getMonth() + 1;
    const todayDate = today.getDate();
    const todayYear = today.getFullYear();

    if (todayMonth >= 7 && todayDate >= 1) {
        return todayYear + 1;
    }

    return todayYear;
};

export const activeGradhsPeriod = () => {
    const currentGradhs = activeGradhs();
    const startYear = currentGradhs - 1;
    const upcomingYears = _.range(currentGradhs, currentGradhs + 4);

    return [startYear, ...upcomingYears];
};

export const gradhsYears = () => {
    const endYear = activeGradhs() + 7;
    const yearsRange = _.range(1996, endYear);

    return yearsRange.reverse();
};

export const modifyFreeYFactors = (factorMap, breakoutTime) => {
    const freeFactors = factorMap.free;
    let firstLength = freeFactors.Y[0];
    let allOtherLengths = freeFactors.Y.all_other;

    if (breakoutTime.toFixed(2) < 3.0) firstLength = 1.35;
    if (breakoutTime.toFixed(2) > 5.1) firstLength = 1.05;
    if (breakoutTime.toFixed(2) > 3.7 && breakoutTime.toFixed(2) <= 5.1) {
        firstLength = 1.15;
    }

    if (breakoutTime.toFixed(2) < 1.7) allOtherLengths = 1.61;
    if (breakoutTime.toFixed(2) > 3.5) allOtherLengths = 1.31;
    if (breakoutTime.toFixed(2) > 2.7 && breakoutTime.toFixed(2) <= 3.5) {
        allOtherLengths = 1.41;
    }

    return {
        ...freeFactors,
        Y: { 0: firstLength, all_other: allOtherLengths },
    };
};

export const modifyBackYFactors = (factorMap, breakoutTime) => {
    const backFactors = factorMap.back;
    let firstLength = backFactors.Y[0];
    let allOtherLengths = backFactors.Y.all_other;

    if (breakoutTime.toFixed(2) < 5.2) firstLength = 1.25;
    if (breakoutTime.toFixed(2) > 6.3) firstLength = 1.05;

    if (breakoutTime.toFixed(2) < 3.3) allOtherLengths = 1.35;
    if (breakoutTime.toFixed(2) > 5.0) allOtherLengths = 1.15;

    return {
        ...backFactors,
        Y: { 0: firstLength, all_other: allOtherLengths },
    };
};

export const modifyFlyYFactors = (factorMap, breakoutTime) => {
    const flyFactors = factorMap.fly;
    let firstLength = flyFactors.Y[0];
    let allOtherLengths = flyFactors.Y.all_other;

    if (breakoutTime.toFixed(2) < 3.8) firstLength = 1.32;
    if (breakoutTime.toFixed(2) > 5.5) firstLength = 1.12;

    if (breakoutTime.toFixed(2) < 2.2) allOtherLengths = 1.5;
    if (breakoutTime.toFixed(2) > 5.0) allOtherLengths = 1.2;
    if (breakoutTime.toFixed(2) > 3.1 && breakoutTime.toFixed(2) <= 5.0) {
        allOtherLengths = 1.3;
    }

    return {
        ...flyFactors,
        Y: { 0: firstLength, all_other: allOtherLengths },
    };
};

export const modifyBreastYFactors = (factorMap, breakoutTime) => {
    const breastFactors = factorMap.breast;
    let firstLength = breastFactors.Y[0];
    let allOtherLengths = breastFactors.Y.all_other;

    if (breakoutTime.toFixed(2) < 4.8) firstLength = 1.38;
    if (breakoutTime.toFixed(2) > 5.7) firstLength = 1.18;

    if (breakoutTime.toFixed(2) < 3.3) allOtherLengths = 1.5;
    if (breakoutTime.toFixed(2) > 4.7) allOtherLengths = 1.3;

    return {
        ...breastFactors,
        Y: { 0: firstLength, all_other: allOtherLengths },
    };
};

export const getSwimmerDateAge = (splash, dateOfSwim) => {
    if (!splash || !dateOfSwim) return null;

    const swimmerDOB =
        splash.swimmer && splash.swimmer.dateofbirth
            ? splash.swimmer.dateofbirth
            : null;
    const swimmerYearBorn =
        splash.swimmer && splash.swimmer.year_born
            ? splash.swimmer.year_born
            : null;

    if (
        swimmerDOB &&
        swimmerDOB instanceof Date &&
        dateOfSwim instanceof Date &&
        swimmerDOB.getFullYear() < dateOfSwim.getFullYear()
    ) {
        let substractNumber = 0;

        if (dateOfSwim.getMonth() + 1 < swimmerDOB.getMonth() + 1) {
            substractNumber = 1;
        }

        if (
            dateOfSwim.getMonth() + 1 === swimmerDOB.getMonth() + 1 &&
            dateOfSwim.getDate() < swimmerDOB.getDate()
        ) {
            substractNumber = 1;
        }

        return (
            dateOfSwim.getFullYear() -
            swimmerDOB.getFullYear() -
            substractNumber
        );
    }

    if (
        swimmerYearBorn &&
        dateOfSwim instanceof Date &&
        swimmerYearBorn < dateOfSwim.getFullYear()
    ) {
        return dateOfSwim.getFullYear() - swimmerYearBorn;
    }

    return null;
};

export const getSetDifference = (setA, setB) => {
    return new Set([...setA].filter((element) => !setB.has(element)));
};

export const eventageToAgeRange = (eventage) => {
    if (!eventage || eventage === "UNOV" || eventage === "ALL") {
        return { minAge: null, maxAge: null };
    }

    return {
        minAge: minAgeGroupHexToInt(eventage.substring(0, 2)),
        maxAge: maxAgeGroupHexToInt(eventage.substring(2, 4)),
    };
};

export const ageRangeToEventAge = (minAge, maxAge) => {
    return `${minAgeGroupHex(minAge)}${maxAgeGroupHex(maxAge)}`;
};

// exists on the backend too
export const displayAgerange = (
    minAge,
    maxAge,
    useSign,
    { hideOpen = false } = {},
) => {
    if (!minAge && !maxAge) {
        return !hideOpen ? "Open" : "";
    }
    if (!minAge) {
        return `${maxAge}${useSign ? "-" : " and under"}`;
    }
    if (!maxAge) {
        return `${minAge}${useSign ? "+" : " and over"}`;
    }
    if (minAge === maxAge) {
        return `${minAge}`;
    }
    return `${minAge} - ${maxAge}`;
};

// exists on the backend too
export const displayEventage = (
    eventage,
    { useSign = true, hideOpen = false } = {},
) => {
    if (!eventage) {
        return "";
    }

    const minAge = minAgeGroupHexToInt(eventage.substring(0, 2));
    const maxAge = maxAgeGroupHexToInt(eventage.substring(2, 4));

    return displayAgerange(minAge, maxAge, useSign, hideOpen);
};

export const inAgeRange = (age, minAge, maxAge) => {
    return (!minAge || minAge <= age) && (!maxAge || maxAge >= age);
};

export const inAgeGroupRange = (age, numlegs, minAge, maxAge, isCombined) => {
    // age groups with minAge >= 100 canot be single
    const combined = isCombined || minAge >= 100;

    if (numlegs === 1 && combined) {
        // relay agegroup (e.g. 100-120)
        // against a relayLeg (25y.o)
        // it doesn't make sense to validate
        return true;
    }

    // when we validate realys against single age groups there is a global
    // min/max which needs to be verified e.g. in 13-14, total max cannot
    // exceed 56 and for 25-30, total max cannot subceed 100
    if (numlegs > 1 && !combined) {
        const gmin = minAge >= 25 ? numlegs * minAge : null;
        const gmax = maxAge <= 25 ? numlegs * maxAge : null;

        return inAgeRange(age, gmin, gmax);
    }

    return inAgeRange(age, minAge, maxAge);
};

export const ageRangeSortKey = (ageRange) => {
    const { minAge, maxAge } = ageRange;

    // master/relay agegroups start with minAge >= 25
    if (!minAge || minAge < 25) {
        // this is a youngser age group, older is stronger
        return `${3}-${maxAge || 1000}-${minAge || 0}`;
    }

    // master/relay (younger is stronger)
    // note: if necessary we could split by minAge >= 100
    // or we could swap maxAge/minAge order to change the traversion path
    return `${2}-${-1 * (maxAge || 1000)}-${-1 * (minAge || 0)}`;
};

export const eventAgeSortKey = (eventAge) => {
    if (eventAge === "UNOV") return `${4}-${1000}-${1000}`;

    return ageRangeSortKey(eventageToAgeRange(eventAge));
};

export const divMod = (x, y) => {
    if (!x || !y) return { quotient: 0, remainder: 0 };

    const quotient = Math.floor(x / y);
    const remainder = x % y;

    return { quotient, remainder };
};

export const zip = (arr1, arr2) => {
    return arr1.map((k, i) => [k, arr2[i]]);
};

export const zipLongest = ([...args], fillvalue = null) => {
    const result = [];
    let i = 0;

    // eslint-disable-next-line no-loop-func
    while (args.some((argArray) => argArray[i])) {
        // eslint-disable-next-line no-loop-func
        const ithColumn = args.map((argArray) => {
            const item =
                typeof argArray[i] === "undefined" ? fillvalue : argArray[i];
            return item;
        });

        result.push(ithColumn);

        i += 1;
    }

    return result;
};

export const getAge = (dateString) => {
    const today = new Date();
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age -= 1;
    }

    return age;
};

export const getEventSwimoffRound = (round) => {
    if (_.has(EVENTROUND_SWIMOFF, round)) {
        return EVENTROUND_SWIMOFF[round];
    }

    return null;
};

export const getSwimoffEventRound = (round) => {
    if (_.has(SWIMOFF_EVENTROUND, round)) {
        return SWIMOFF_EVENTROUND[round];
    }

    return null;
};

export const isStringNumeric = (str) => {
    if (typeof str !== "string") return false;

    return !Number.isNaN(str) && !Number.isNaN(parseFloat(str));
};

export const getOperatingSystemName = () => {
    // "Keep in mind that users of a browser can change
    // the value of this field if they want (UA spoofing)"
    // - MDN Web Docs

    let operatingSystem = "Unknown";

    if (navigator && navigator.userAgent) {
        if (navigator.userAgent.indexOf("X11") !== -1) {
            operatingSystem = "Unix";
        }
        if (navigator.userAgent.indexOf("Win") !== -1) {
            operatingSystem = "Windows";
        }
        if (navigator.userAgent.indexOf("Mac") !== -1) {
            operatingSystem = "macOS";
        }
        if (navigator.userAgent.indexOf("Linux") !== -1) {
            operatingSystem = "Linux";
        }
        if (navigator.userAgent.indexOf("Android") !== -1) {
            operatingSystem = "Android";
        }
        if (
            navigator.userAgent.indexOf("iPhone") !== -1 ||
            navigator.userAgent.indexOf("iPad") !== -1 ||
            navigator.userAgent.indexOf("iPod") !== -1
        ) {
            operatingSystem = "iOS";
        }
    }

    return operatingSystem;
};

export const isModifiedEvent = (event) =>
    Boolean(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);

export const parseSeedRules = (seedRules) => ({
    ...seedRules,
    lanePreferences: seedRules.lane_preferences.split(",").map(Number),
    numLanes: seedRules.lane_preferences.split(",").length,
});

export const parseScoreRules = (scoreRules) => ({
    ...scoreRules,
    individualPoints: scoreRules.individual_points.split(",").map(Number),
    relayPoints: scoreRules.relay_points.split(",").map(Number),
});

export const getAgeGroupName = ({ eventage, hsclass, name }) => {
    if (eventage === DEFAULT_TIMES_AGE_GROUP.eventage) return eventage;

    const autoName = hsclass
        ? getHsClassName(hsclass)
        : getEventAgeName({ eventage, abbr: true });

    // name.replace(/\s+/g, "") will replace whitespace
    // on auto name generated ageGroups:
    // when we auto generate age group the name will be: 40 - 45
    // and then when parsing the abbr will be : 40-45
    // which is the same so we replace white spaces
    const formattedName = name ? name.replace(/\s+/g, "") : "";
    const names = _.uniq([formattedName, autoName].filter(Boolean));
    if (names[1]) names[1] = `(${names[1]})`;

    return names.join(" ");
};

export const getAgeGroupNameMap = (meetEvent) =>
    _.fromPairs(
        meetEvent.agegroups
            ? meetEvent.agegroups.map((agegroup) => [
                  agegroup.eventage,
                  getAgeGroupName(agegroup),
              ])
            : [],
    );

export const orderEventList = (eventList) =>
    _.orderBy(eventList, ["ordernumber", "id"]);

export const collectEventAttrs = ({
    combinedRounds = [],
    splashes = [],
    canManageMeet,
}) => {
    const sessionIds = new Set();
    const eventgenders = new Set();
    const eventIds = new Set();
    const eventages = new Set();
    const varsities = new Set();
    const eventrounds = new Set();
    const swimcourses = new Set();
    const divecourses = new Set();

    combinedRounds.forEach((meetEvent) => {
        sessionIds.add(meetEvent.session_id);
        eventgenders.add(meetEvent.eventgender);
        eventIds.add(`${meetEvent.eventstroke}${meetEvent.eventdistance}`);
        eventages.add(meetEvent.eventage);
        varsities.add(meetEvent.varsity);
        eventrounds.add(meetEvent.eventround);
        const courses = meetEvent.isDiving ? divecourses : swimcourses;
        courses.add(meetEvent.eventcourse);
    });

    splashes.forEach((splash) => {
        eventgenders.add(splash.eventgender);
        eventIds.add(`${splash.eventstroke}${splash.eventdistance}`);
        eventages.add(splash.eventage);

        if (canManageMeet) {
            if (splash.extra_data) {
                varsities.add(splash.extra_data.varsity);
            }
        } else {
            varsities.add(splash.varsity);
        }

        eventrounds.add(splash.eventround);
        const courses = splash.isDiving ? divecourses : swimcourses;
        if (splash.eventtime) courses.add(splash.eventcourse);
    });

    return {
        sessionIds: [...sessionIds],
        eventgenders: [...eventgenders],
        eventIds: [...eventIds],
        eventages: [...eventages],
        varsities: [...varsities],
        eventrounds: [...eventrounds],
        swimcourses: [...swimcourses],
        divecourses: [...divecourses],
    };
};
export const getAgeGroupShortName = ({ eventage, hsclass }) => {
    const autoName = hsclass || getEventAgeName({ eventage, abbr: true });
    return autoName;
};

export const getAgeGroupShortNameMap = (ageGroups) =>
    _.fromPairs(
        ageGroups
            ? ageGroups.map((ageGroup) => [
                  ageGroup.eventage,
                  getAgeGroupShortName(ageGroup),
              ])
            : [],
    );

export const getAgesVarsityName = ({
    ageGroups,
    varsity,
    maxChars = null,
    groupTogether = false,
}) => {
    const eventages =
        ageGroups && ageGroups.length > 0
            ? ageGroups.map((ageGroup) => ageGroup.eventage)
            : ["UNOV"];

    const ageGroupNameMap = getAgeGroupShortNameMap(ageGroups);

    let displayAge = "";

    if (!groupTogether && maxChars !== 16 && eventages.length > 2) {
        displayAge = `${eventages.length} groups`;
    } else {
        displayAge =
            eventages.join(",") !== DEFAULT_EVENTAGE &&
            eventages.map((eventage) => ageGroupNameMap[eventage]).join(", ");
    }

    // writeEventsToSequence
    if (maxChars === 16) {
        displayAge =
            eventages.join(",") !== DEFAULT_EVENTAGE &&
            eventages
                .map((eventage) => getEventAgeName({ eventage, abbr: true }))
                .join(", ");
    }

    const name =
        [displayAge && `(${displayAge})`, varsity && `(${varsity})`]
            .filter(Boolean)
            .join(" ") || "(Open)";

    return maxChars && name.length > maxChars ? "" : name;
};

export const appendToEventsName = ({
    eventsArr,
    getAttr,
    forceAppend = false,
    isAgesVarsityName = false,
}) =>
    _.fromPairs(
        _.flatten(
            _.toPairs(eventsArr).map(([name, events]) => {
                const hasMany =
                    new Set(
                        events.map((meetEvent) =>
                            getAttr(meetEvent, isAgesVarsityName),
                        ),
                    ).size > 1;

                return _.toPairs(
                    _.groupBy(events, (meetEvent) => [
                        [name, (hasMany || forceAppend) && getAttr(meetEvent)]
                            .filter(Boolean)
                            .join(" "),
                    ]),
                );
            }),
        ),
    );

let lastTmpId = 0;
export const getTmpId = () => {
    lastTmpId += 1;
    return _.padStart(lastTmpId, 10, "0");
};

export const getRoundStatusColor = (status) =>
    ({
        unmatched: "c-event-status c-event-status--unmatched",
        upcoming: "c-event-status c-event-status--upcoming",
        not_seeded: "c-event-status c-event-status--not-seeded",
        seeded: "c-event-status c-event-status--seeded",
        in_progress: "c-event-status c-event-status--in-progress",
        completed: "c-event-status c-event-status--completed",
        scratches_open: "c-event-status c-event-status--scratches-open",
        scored: "c-event-status c-event-status--scored",
    })[status] || "c-event-status c-event-status--upcoming";

export const getRoundStatusTitle = (status) =>
    ({
        unmatched: "Unmatched",
        upcoming: "Upcoming",
        not_seeded: "Not seeded",
        seeded: "Seeded",
        in_progress: "In progress",
        completed: "Completed",
        scratches_open: "Scratches open",
        scored: "Scored",
    })[status] || status;

export const subtractTime = (minuend, subtrahend) => {
    const diff = (Number(minuend) || 0) - (Number(subtrahend) || 0);
    return diff > 0 ? diff.toFixed(2) : "";
};

export const cleanRelay = ({
    relay: initialRelay,
    legs: initialLegs,
    authUser,
    canManageMeet,
}) => {
    const relay = initialRelay.copy();
    let legs = [...initialLegs];

    // make sure exhibition is boolean
    relay.exhibition = Boolean(relay.exhibition);

    if (canManageMeet) {
        relay.extra_data = { ...relay.extra_data, updated_by_id: authUser.id };
    }

    // ensure that each leg has unique legposition
    const usedPositions = new Set();
    let lastAlternate = Math.max(
        ...legs
            .map((leg) => leg.legposition)
            .filter((legposition) => legposition),
        START_ALTERNATES_FROM,
    );

    legs = legs.map((leg) => {
        const { legposition } = leg || {};

        if (
            !legposition ||
            usedPositions.has(legposition) ||
            (legposition > relay.numlegs &&
                legposition <= START_ALTERNATES_FROM)
        ) {
            lastAlternate += 1;

            usedPositions.add(lastAlternate);

            return leg.copyWith({ legposition: lastAlternate });
        }

        usedPositions.add(legposition);

        return leg;
    });

    // double check unique legposition
    legs = _.uniqBy(legs, "legposition");

    // order by legposition
    legs = _.orderBy(legs, ["legposition"]);

    // ensure that all legs have swimmers
    if (canManageMeet) {
        legs = legs.filter((leg) => leg.swimmer && leg.swimmer.orbitId);
    } else {
        legs = legs.filter((leg) => leg.swimmer && leg.swimmer.id);
    }

    const meetEvent = {
        eventstroke: relay.eventstroke,
        eventdistance: relay.eventdistance,
        eventcourse: relay.eventcourse,
        eventgender: relay.eventgender,
    };

    // reassign properties that depend on relay (event, exhibition etc.)
    const legEvents = getLegsEvents({
        meetEvent,
        numlegs: relay.numlegs,
    });
    const strokeByPosition = _.fromPairs(
        legEvents.map((legevent) => [
            legevent.legposition,
            legevent.eventstroke,
        ]),
    );
    const { eventdistance: legdistance } = legEvents[0] || {};

    let relaySplitTimes = [];
    let relayVaristy = "";
    if (canManageMeet) {
        relaySplitTimes =
            relay.split && relay.split.splittimes
                ? relay.split.splittimes.split(",")
                : [];
        relayVaristy = relay.extra_data ? relay.extra_data.varsity : "";
    } else {
        relaySplitTimes = relay.splittimes ? relay.splittimes.split(",") : [];
        relayVaristy = relay.varsity || "";
    }

    const { legSplitsCount } = relay || {};

    legs = legs.map((initialLeg) => {
        const leg = initialLeg.copy();
        let eventStroke =
            strokeByPosition[leg.legposition] || leg.eventstroke || "";

        if (leg.isAlternate) {
            eventStroke = meetEvent.eventstroke;
        }

        if (canManageMeet) {
            leg.can_manage_splash = true;
            leg.date_created = leg.date_created || new Date();
            leg.date_updated = leg.date_created || new Date();
            leg.dateofswim = relay.dateofswim;
            leg.eventage = relay.eventage;
            leg.eventcourse = relay.eventcourse;
            leg.eventdistance = legdistance || 0;
            leg.eventgender = relay.eventgender;
            leg.eventnumber = relay.eventnumber;
            leg.eventround = relay.eventround;
            leg.eventstroke = eventStroke;
            leg.eventtime = leg.eventtime || null;
            leg.exhibition = relay.exhibition;
            leg.extra_data = {
                backup_1: (leg.extra_data && leg.extra_data.backup_1) || null,
                backup_2: (leg.extra_data && leg.extra_data.backup_2) || null,
                backup_3: (leg.extra_data && leg.extra_data.backup_3) || null,
                backup_calculated:
                    (leg.extra_data && leg.extra_data.backup_calculated) ||
                    null,
                dq_code: (leg.extra_data && leg.extra_data.dq_code) || "",
                heat_place:
                    (leg.extra_data && leg.extra_data.heat_place) || null,
                inserted_by_id:
                    (leg.extra_data && leg.extra_data.inserted_by_id) ||
                    authUser.id,
                intrasquad_id: leg.extra_data
                    ? leg.extra_data.intrasquad_id
                    : null,
                seed_id: (leg.extra_data && leg.extra_data.seed_id) || null,
                stopwatch_1:
                    (leg.extra_data && leg.extra_data.stopwatch_1) || null,
                stopwatch_2:
                    (leg.extra_data && leg.extra_data.stopwatch_2) || null,
                stopwatch_3:
                    (leg.extra_data && leg.extra_data.stopwatch_3) || null,
                stopwatch_calculated:
                    (leg.extra_data && leg.extra_data.stopwatch_calculated) ||
                    null,
                updated_by_id: authUser.id,
                varsity: relayVaristy,
            };
            leg.fina_points = leg.fina_points || null;
            leg.flag = leg.flag || "";
            leg.flystart =
                leg.flystart ||
                (leg.legposition && leg.legposition !== 1) ||
                false;
            leg.heat = relay.heat;
            leg.lane = relay.lane;
            leg.meet = relay.meet;
            leg.meet_id = relay.meet_id;
            leg.meetOrbitId = relay.meetOrbitId;
            leg.numlegs = 1;
            leg.orgcode = relay.orgcode;
            leg.parent_id = leg.parent_id || null;
            leg.place = leg.place || "";
            leg.pointsscored = leg.pointsscored || null;
            leg.pointvalue = leg.pointvalue || null;
            leg.relay_id = relay.id || leg.relay_id;
            leg.relayOrbitId = relay.orbitId;
            leg.relayname = relay.relayname;
            leg.season_id = leg.season_id || relay.season_id;
            leg.seedcourse = leg.seedcourse || relay.seedcourse;
            leg.seedtime = leg.seedtime || null;
            leg.split = {
                splitdistance: (leg.split && leg.split.splitdistance) || null,
                splittimes: (leg.split && leg.split.splittimes) || "",
            };
            leg.swimmer = leg.swimmer || null;
            leg.swimmer_id = leg.swimmer_id || null;
            leg.swimmerOrbitId = leg.swimmerOrbitId || null;
            leg.team = relay.team;
            leg.team_id = relay.team ? relay.team.id : relay.team_id;
            leg.teamOrbitId = relay.teamOrbitId;
            leg.timecode = relay.timecode;
            leg.user_id = null;
            leg.year = relay.year;
        } else {
            leg.relay_id = relay.id || leg.relay_id;
            leg.eventstroke = eventStroke;
            leg.eventnumber = relay.eventnumber;
            leg.eventgender = relay.eventgender;
            leg.eventdistance = legdistance || 0;
            leg.eventround = relay.eventround;
            leg.eventage = relay.eventage;
            leg.varsity = relayVaristy;
            leg.eventcourse = relay.eventcourse;
            leg.seedcourse = leg.seedcourse || relay.seedcourse;
            leg.numlegs = 1;
            leg.heat = relay.heat;
            leg.lane = relay.lane;
            leg.relayname = relay.relayname;
            leg.team = relay.team;
            leg.exhibition = relay.exhibition;
            leg.timecode = relay.timecode;
        }

        if (legSplitsCount) {
            const prevSplitIndex = (leg.legposition - 1) * legSplitsCount - 1;
            const prevTime = relaySplitTimes[prevSplitIndex] || "";

            const legSplits = _.range(legSplitsCount)
                .map((legSplitIndex) => {
                    const relaySplitIndex =
                        (leg.legposition - 1) * legSplitsCount + legSplitIndex;

                    return relaySplitTimes[relaySplitIndex] || "";
                })
                .map(
                    (splitTime) =>
                        splitTime && subtractTime(splitTime, prevTime),
                );

            const hasSplits = legSplits.some(Boolean);

            if (canManageMeet) {
                if (leg.split) {
                    leg.split.splittimes = hasSplits ? legSplits.join(",") : "";
                    leg.split.splitdistance = hasSplits
                        ? relay.split.splitdistance
                        : null;
                }
            } else {
                leg.splittimes = hasSplits ? legSplits.join(",") : "";
                leg.splitdistance = hasSplits ? relay.splitdistance : null;
            }
        }

        return leg;
    });

    return { relay, legs };
};

export const getTimesLabel = (meet) =>
    meet && meet.UPCOMING ? "Entries" : "Results";

export const getTimesLabelLower = (meet) =>
    meet && meet.UPCOMING ? "entries" : "results";

export const getTimeLabelLower = (meet) =>
    meet && meet.UPCOMING ? "entry" : "result";

export const getNoTimesLabel = (meet) =>
    meet && meet.UPCOMING ? "No entries" : "No results";

export const orderEntries = ({
    splashes,
    order,
    combinedRounds,
    meetEvent = {},
}) => {
    let ordered;

    if (order === ENTRIES_ORDER_PLACE || order === `-${ENTRIES_ORDER_PLACE}`) {
        ordered = _.orderBy(splashes, [
            (x) => !x.place,
            (x) => Number(x.place),
        ]);
    } else if (
        order === ENTRIES_ORDER_SWIMMER ||
        order === `-${ENTRIES_ORDER_SWIMMER}`
    ) {
        ordered = _.orderBy(
            splashes,
            (x) =>
                (x.swimmer && x.swimmer.name) ||
                (x.team && (x.team.abbr || x.team.name)),
        );
    } else if (
        order === ENTRIES_ORDER_TEAM ||
        order === `-${ENTRIES_ORDER_TEAM}`
    ) {
        ordered = _.orderBy(
            splashes,
            (x) => x.team && (x.team.abbr || x.team.name),
        );
    } else if (
        order === ENTRIES_ORDER_SESSION ||
        order === `-${ENTRIES_ORDER_SESSION}`
    ) {
        const eventRoundSessionMap = {};

        combinedRounds.forEach((event) => {
            if (!event) return;

            if (!_.has(eventRoundSessionMap, event.secondNaturalKey)) {
                eventRoundSessionMap[event.secondNaturalKey] = event.session
                    ? event.session.number
                    : event.session_number;
            }
        });

        ordered = _.orderBy(
            splashes,
            (x) => eventRoundSessionMap[x.eventRoundNK],
        );
    } else if (
        order === ENTRIES_ORDER_EVENT ||
        order === `-${ENTRIES_ORDER_EVENT}`
    ) {
        const orderedEventnumbers = combinedRounds.map((x) => x.eventnumber);
        ordered = _.orderBy(splashes, (x) =>
            orderedEventnumbers.indexOf(x.eventnumber),
        );
    } else if (
        order === ENTRIES_ORDER_NUMERIC_ID ||
        order === `-${ENTRIES_ORDER_NUMERIC_ID}`
    ) {
        ordered = _.orderBy(splashes, [
            (x) => x.eventstroke,
            (x) => x.eventdistance,
        ]);
    } else if (
        order === ENTRIES_ORDER_NUMBER ||
        order === `-${ENTRIES_ORDER_NUMBER}`
    ) {
        const orderedCombinedRounds = _.orderBy(
            combinedRounds,
            (x) => x.eventnumber,
        );
        const orderedEventnumbers = orderedCombinedRounds.map(
            (x) => x.eventnumber,
        );
        ordered = _.orderBy(splashes, (x) =>
            orderedEventnumbers.indexOf(x.eventnumber),
        );
    } else if (
        order === ENTRIES_ORDER_SEEDTIME ||
        order === `-${ENTRIES_ORDER_SEEDTIME}`
    ) {
        ordered = orderSplashes({ splashes, time: "seedtime", meetEvent });
    } else if (
        order === ENTRIES_ORDER_EVENTTIME ||
        order === `-${ENTRIES_ORDER_EVENTTIME}`
    ) {
        ordered = orderSplashes({ splashes, meetEvent });
    } else {
        ordered = _.orderBy(splashes, [
            (x) => x.isRelay,
            (x) => x.isDiving,
            (x) => x.id,
        ]);
    }

    return order && order.startsWith("-") ? ordered.reverse() : ordered;
};

export const getSplashErrors = ({ splash, errorMap }) => {
    let config = {};

    if (_.has(errorMap, "bySplash")) {
        if (errorMap) {
            config = _.has(errorMap.bySplash, splash.orbitId)
                ? errorMap.bySplash[splash.orbitId]
                : {};
        }

        return (config && config.errors) || [];
    }

    if (errorMap && errorMap.by_splash) {
        config = errorMap.by_splash[splash.id];
    }

    return (config && config.errors) || [];
};

export const focusOnAdd = (index) => `@dashboard/autocomplete-${index}`;

export const focusOnEventtime = (splash) =>
    `@dashboard/splash-${splash.syncId}-eventtime`;

export const focusOnSeedtime = (splash) =>
    `@dashboard/splash-${splash.syncId}-seedtime`;

export const focusOnLegEventtime = (leg) =>
    `@relay-modal/leg-${leg.legindex}-eventtime`;

export const focusOnLegSeedtime = (leg) =>
    `@relay-modal/leg-${leg.legindex}-seedtime`;

export const focusOnLegSwimmer = (leg) =>
    `@relay-modal/leg-${leg.legindex}-swimmer`;

export const focusOnRelayEventtime = () => `@relay-modal/relay-eventtime`;

export const focusOnRelaySeedtime = () => `@relay-modal/relay-seedtime`;

export const focusOnRelaySave = () => `@relay-modal/relay-save`;

export const getNextFocusOn = ({ currentFocusOn, flowFocusItems }) => {
    const currentIndex = flowFocusItems.findIndex(
        (focusItem) => focusItem.name === currentFocusOn,
    );

    const nextItem =
        currentIndex !== -1 &&
        flowFocusItems
            .slice(currentIndex + 1)
            .find((focusItem) => !focusItem.skipFocus);

    return nextItem ? nextItem.name : null;
};

const getTimecodeStage = ({ meetEvent, entryRules }) => {
    if (meetEvent.status === "scored") return TIMECODE_STAGES.completed;

    if (meetEvent.status === "completed") return TIMECODE_STAGES.completed;

    if (meetEvent.status === "in_progress") return TIMECODE_STAGES.in_progress;

    if (meetEvent.status === "seeded") return TIMECODE_STAGES.seeded;

    return entryRules.entries_closed
        ? TIMECODE_STAGES.entries_closed
        : TIMECODE_STAGES.entries_open;
};

export const getEventTimecodeKeys = ({ meetEvent, entryRules }) =>
    ({
        [TIMECODE_STAGES.entries_open]: [],
        [TIMECODE_STAGES.entries_closed]: ["SCR"],
        [TIMECODE_STAGES.seeded]: ["SCR", "DFS"],
        [TIMECODE_STAGES.in_progress]: ["NT", "NS", "DNF", "DQ", "SCR", "DFS"],
        [TIMECODE_STAGES.completed]: _.values(TIMECODE_MAP),
    })[getTimecodeStage({ meetEvent, entryRules })];

export const getEventTimecodes = ({ meetEvent, entryRules }) => {
    const keys = getEventTimecodeKeys({ meetEvent, entryRules });

    return keys
        .map((key) => Number(INVERTED_TIMECODE_MAP[key]))
        .filter(Boolean);
};

export const getDqCodes = ({ eventstroke }) =>
    eventstroke !== "H"
        ? [...DQ_CODES[eventstroke], ...MISCELLANEOUS_DQ_CODES]
        : MISCELLANEOUS_DQ_CODES;

export const parseCtsTime = (value) => (value ? (value / 1000).toFixed(2) : "");

export const parseDakTime = (value) => (value ? value.toFixed(2) : "");

export const orderHeats = (heats) => _.orderBy(heats, [(x) => !x, (x) => x]);

export const orderLanes = (lanes) => _.orderBy(lanes, [(x) => !x, (x) => x]);

export const getHeats = ({ meetEvent, splashes }) => {
    const heats = _.uniq([
        ...(splashes || []).map((splash) => splash.heat),
        ...((meetEvent && meetEvent.heats) || []).map((heat) => heat.number),
    ]).map((heat) => heat || null);
    return orderHeats(heats);
};

export const getLanes = ({ splashes = [], numLanes }) =>
    orderLanes(
        _.uniq(
            _.concat(
                splashes.map((x) => x.lane),
                _.range(1, numLanes + 1),
            ),
        ),
    );

export const limitManageableLanes = ({
    canManageMeet,
    entryRules,
    seedRules,
}) =>
    !canManageMeet &&
    entryRules &&
    !entryRules.entries_closed &&
    seedRules &&
    seedRules.seed_preferences === "LB";

export const allowSeedOverrides = ({ splash, meet, entryRules }) => {
    const { isRelay, exhibition } = splash || {};
    const { valid_seedtime_required } = meet || {};
    const {
        allow_indrecord_overrides,
        allow_relay_overrides,
        allow_relays_aggregate,
    } = entryRules || {};

    return (
        exhibition ||
        !valid_seedtime_required ||
        (isRelay
            ? allow_relay_overrides || allow_relays_aggregate
            : allow_indrecord_overrides)
    );
};

export const calculateBackup = (backups) => {
    const valuedBackups = backups.filter(Boolean);

    return valuedBackups.length ? _.mean(valuedBackups).toFixed(2) : null;
};

export const passCtsLaneToSplash = ({ ctsLane, splash, canManageMeet }) => {
    const {
        place,
        split_times,
        final_time,
        individual_button_times,
        backup_time,
    } = ctsLane;

    const splitTimes = split_times ? split_times.split(",").map(Number) : [];

    const individualButtonTimes = individual_button_times
        ? individual_button_times.split(",").map(Number)
        : [];

    const allSplits = [...splitTimes, final_time];

    // when only one backup button is used, it's stored in backup_time field
    // and individual_button_times field is empty
    const backups = individualButtonTimes.length
        ? individualButtonTimes
        : [backup_time];

    const backupCalculated = calculateBackup(backups);

    let updateObj = {};

    if (canManageMeet) {
        updateObj = {
            eventtime: parseCtsTime(final_time),
            timecode:
                // if DQ is set by CTS, set to DQ
                (place === -1 && Number(INVERTED_TIMECODE_MAP.DQ)) ||
                // if final time is missing, keep current timecode or set to NT
                (!final_time &&
                    (splash.timecode || Number(INVERTED_TIMECODE_MAP.NT))) ||
                // if final time is present and timecode is DQ or DFS, keep current timecode
                (["DQ", "DFS"].includes(TIMECODE_MAP[splash.timecode]) &&
                    splash.timecode) ||
                // if final time is present, unset timecode
                null,
            split: {
                ...splash.split,
                splittimes: allSplits
                    .map((splitTime) => parseCtsTime(splitTime) || "")
                    .join(","),

                splitdistance: Math.floor(
                    splash.eventdistance / allSplits.length,
                ),
            },
            extra_data: {
                ...splash.extra_data,
                backup_1: backups[0] ? parseCtsTime(backups[0]) : null,
                backup_2: backups[1] ? parseCtsTime(backups[1]) : null,
                backup_3: backups[2] ? parseCtsTime(backups[2]) : null,
                backup_calculated: backupCalculated
                    ? parseCtsTime(backupCalculated)
                    : null,
                heat_place: place > 0 ? place : null,
            },
        };
    } else {
        updateObj = {
            heat_place: place > 0 ? place : null,
            eventtime: parseCtsTime(final_time),
            timecode:
                place === -1
                    ? Number(_.invert(TIMECODE_MAP).DQ)
                    : splash.timecode,
            splittimes: allSplits
                .map((splitTime) => parseCtsTime(splitTime) || "")
                .join(","),
            splitdistance: Math.floor(splash.eventdistance / allSplits.length),
            backup_1: backups[0] ? parseCtsTime(backups[0]) : null,
            backup_2: backups[1] ? parseCtsTime(backups[1]) : null,
            backup_3: backups[2] ? parseCtsTime(backups[2]) : null,
            backup_calculated: backupCalculated
                ? parseCtsTime(backupCalculated)
                : null,
        };
    }

    return splash.copyWith(updateObj);
};

export const passSstLaneToSplash = ({ sstLane, splash, canManageMeet }) => {
    const { place, splits, finalTime, backups } = sstLane;

    let backupOneTime = null;
    let backupTwoTime = null;
    let backupThreeTime = null;
    let backupCalculatedTime = null;

    if (backups && backups.length) {
        backupOneTime = backups[0] || null;
        backupTwoTime = backups[1] || null;
        backupThreeTime = backups[2] || null;

        const allBackups = [backupOneTime, backupTwoTime, backupThreeTime]
            .filter((x) => x)
            .map(Number);

        backupCalculatedTime = allBackups.length
            ? calculateBackup(allBackups)
            : null;
    }

    let updateObj = {};

    if (canManageMeet) {
        updateObj = {
            eventtime: finalTime,
            timecode:
                // if DQ is set by SST, set to DQ
                (place === "Q" && Number(INVERTED_TIMECODE_MAP.DQ)) ||
                // if final time is missing, keep current timecode or set to NT
                (!finalTime &&
                    (splash.timecode || Number(INVERTED_TIMECODE_MAP.NT))) ||
                // if final time is present and timecode is DQ or DFS, keep current timecode
                (["DQ", "DFS"].includes(TIMECODE_MAP[splash.timecode]) &&
                    splash.timecode) ||
                // if final time is present, unset timecode
                null,
            extra_data: {
                ...splash.extra_data,
                backup_1: backupOneTime,
                backup_2: backupTwoTime,
                backup_3: backupThreeTime,
                backup_calculated: backupCalculatedTime,
                heat_place: _.isNumber(place) && place > 0 ? place : null,
            },
            split: {
                ...splash.split,
                splittimes: splits.join(","),
                splitdistance: Math.floor(splash.eventdistance / splits.length),
            },
        };
    } else {
        updateObj = {
            backup_1: backupOneTime,
            backup_2: backupTwoTime,
            backup_3: backupThreeTime,
            backup_calculated: backupCalculatedTime,
            heat_place: _.isNumber(place) && place > 0 ? place : null,
            eventtime: finalTime,
            timecode:
                // if DQ is set by SST, set to DQ
                (place === "Q" && Number(INVERTED_TIMECODE_MAP.DQ)) ||
                // if final time is missing, keep current timecode or set to NT
                (!finalTime &&
                    (splash.timecode || Number(INVERTED_TIMECODE_MAP.NT))) ||
                // if final time is present and timecode is DQ or DFS, keep current timecode
                (["DQ", "DFS"].includes(TIMECODE_MAP[splash.timecode]) &&
                    splash.timecode) ||
                // if final time is present, unset timecode
                null,
            splittimes: splits.join(","),
            splitdistance: Math.floor(splash.eventdistance / splits.length),
        };
    }

    return splash.copyWith(updateObj);
};

export const passDakLaneToSplash = ({ dakLane, splash, canManageMeet }) => {
    const { backup_times, split_times, status } = dakLane;

    const splitTimes = split_times ? split_times.split(",") : [];
    const parsedSplitTimes = splitTimes.map(Number);
    const finalTime = parsedSplitTimes[parsedSplitTimes.length - 1];

    const backupTimes = backup_times ? backup_times.split(",") : [];
    const parsedBackupTimes = backupTimes.map(Number);
    const backupCalculated = calculateBackup(parsedBackupTimes);

    let updateObj = {};

    if (canManageMeet) {
        updateObj = {
            eventtime: parseDakTime(finalTime),
            timecode:
                // if DQ is set by DAK, set to DQ
                (status === "4" && Number(INVERTED_TIMECODE_MAP.DQ)) ||
                // if final time is missing, keep current timecode or set to NT
                (!finalTime &&
                    (splash.timecode || Number(INVERTED_TIMECODE_MAP.NT))) ||
                // if final time is present and timecode is DQ or DFS, keep current timecode
                (["DQ", "DFS"].includes(TIMECODE_MAP[splash.timecode]) &&
                    splash.timecode) ||
                // if final time is present, unset timecode
                null,
            split: {
                ...splash.split,
                splittimes: parsedSplitTimes.map(parseDakTime).join(","),
                splitdistance: Math.floor(
                    splash.eventdistance / parsedSplitTimes.length,
                ),
            },
            extra_data: {
                ...splash.extra_data,
                backup_1: parsedBackupTimes[0]
                    ? parseDakTime(parsedBackupTimes[0])
                    : null,
                backup_2: parsedBackupTimes[1]
                    ? parseDakTime(parsedBackupTimes[1])
                    : null,
                backup_3: parsedBackupTimes[2]
                    ? parseDakTime(parsedBackupTimes[2])
                    : null,
                backup_calculated: backupCalculated || null,
            },
        };
    } else {
        updateObj = {
            eventtime: parseDakTime(finalTime),
            timecode:
                status === "Q"
                    ? Number(_.invert(TIMECODE_MAP).DQ)
                    : splash.timecode,
            splittimes: parsedSplitTimes.map(parseDakTime).join(","),
            splitdistance: Math.floor(
                splash.eventdistance / parsedSplitTimes.length,
            ),
            backup_1: parsedBackupTimes[0]
                ? parseDakTime(parsedBackupTimes[0])
                : null,
            backup_2: parsedBackupTimes[1]
                ? parseDakTime(parsedBackupTimes[1])
                : null,
            backup_3: parsedBackupTimes[2]
                ? parseDakTime(parsedBackupTimes[2])
                : null,
            backup_calculated: backupCalculated || null,
        };
    }

    return splash.copyWith(updateObj);
};

export const passTdrLaneToSplash = ({ tdrLane, splash, canManageMeet }) => {
    const { place, splits, finalTime, timer1, timer2, timer3 } = tdrLane;

    const splitTimes =
        splits && splits.length
            ? splits.map((split) => swimToDecimal(split.time))
            : [];
    const splitDistance =
        splits && splits.length
            ? Math.floor(splash.eventdistance / splits.length)
            : 0;

    const backups = [timer1, timer2, timer3];

    let backupOneTime = null;
    let backupTwoTime = null;
    let backupThreeTime = null;
    let backupCalculatedTime = null;

    if (backups && backups.length) {
        backupOneTime = backups[0] || null;
        backupTwoTime = backups[1] || null;
        backupThreeTime = backups[2] || null;

        const allBackups = [backupOneTime, backupTwoTime, backupThreeTime]
            .filter((x) => x)
            .map(Number);

        backupCalculatedTime = allBackups.length
            ? calculateBackup(allBackups)
            : null;
    }

    let updateObj = {};

    if (canManageMeet) {
        updateObj = {
            eventtime: finalTime,
            timecode:
                // if DQ is set by TDR, set to DQ
                (place === "Q" && Number(INVERTED_TIMECODE_MAP.DQ)) ||
                // if final time is missing, keep current timecode or set to NT
                (!finalTime &&
                    (splash.timecode || Number(INVERTED_TIMECODE_MAP.NT))) ||
                // if final time is present and timecode is DQ or DFS, keep current timecode
                (["DQ", "DFS"].includes(TIMECODE_MAP[splash.timecode]) &&
                    splash.timecode) ||
                // if final time is present, unset timecode
                null,
            extra_data: {
                ...splash.extra_data,
                backup_1: backupOneTime,
                backup_2: backupTwoTime,
                backup_3: backupThreeTime,
                backup_calculated: backupCalculatedTime,
                heat_place: _.isNumber(place) && place > 0 ? place : null,
            },
            split: {
                ...splash.split,
                splittimes: splitTimes.join(","),
                splitdistance: splitDistance,
            },
        };
    } else {
        updateObj = {
            backup_1: backupOneTime,
            backup_2: backupTwoTime,
            backup_3: backupThreeTime,
            backup_calculated: backupCalculatedTime,
            heat_place: _.isNumber(place) && place > 0 ? place : null,
            eventtime: finalTime,
            timecode:
                // if DQ is set by TDR, set to DQ
                (place === "Q" && Number(INVERTED_TIMECODE_MAP.DQ)) ||
                // if final time is missing, keep current timecode or set to NT
                (!finalTime &&
                    (splash.timecode || Number(INVERTED_TIMECODE_MAP.NT))) ||
                // if final time is present and timecode is DQ or DFS, keep current timecode
                (["DQ", "DFS"].includes(TIMECODE_MAP[splash.timecode]) &&
                    splash.timecode) ||
                // if final time is present, unset timecode
                null,
            splittimes: splitTimes.join(","),
            splitdistance: splitDistance,
        };
    }

    return splash.copyWith(updateObj);
};

export const passDolLaneToSplash = ({ dolLane, splash, canManageMeet }) => {
    const { place, splits, finalTime, backups } = dolLane;

    const splitTimes =
        splits && splits.length ? splits.map((split) => split.time) : [];
    const splitDistance =
        splits && splits.length
            ? Math.floor(splash.eventdistance / splits.length)
            : 0;

    // Dolphin has no backups
    let backupOneTime = null;
    let backupTwoTime = null;
    let backupThreeTime = null;
    let backupCalculatedTime = null;

    if (backups && backups.length) {
        backupOneTime = backups[0] || null;
        backupTwoTime = backups[1] || null;
        backupThreeTime = backups[2] || null;

        const allBackups = [backupOneTime, backupTwoTime, backupThreeTime]
            .filter((x) => x)
            .map(Number);

        backupCalculatedTime = allBackups.length
            ? calculateBackup(allBackups)
            : null;
    }

    let updateObj = {};

    if (canManageMeet) {
        updateObj = {
            eventtime: finalTime,
            timecode:
                // if DQ is set by DOL, set to DQ
                (place === "Q" && Number(INVERTED_TIMECODE_MAP.DQ)) ||
                // if final time is missing, keep current timecode or set to NT
                (!finalTime &&
                    (splash.timecode || Number(INVERTED_TIMECODE_MAP.NT))) ||
                // if final time is present and timecode is DQ or DFS, keep current timecode
                (["DQ", "DFS"].includes(TIMECODE_MAP[splash.timecode]) &&
                    splash.timecode) ||
                // if final time is present, unset timecode
                null,
            extra_data: {
                ...splash.extra_data,
                backup_1: backupOneTime,
                backup_2: backupTwoTime,
                backup_3: backupThreeTime,
                backup_calculated: backupCalculatedTime,
                heat_place: _.isNumber(place) && place > 0 ? place : null,
            },
            split: {
                ...splash.split,
                splittimes: splitTimes.join(","),
                splitdistance: splitDistance,
            },
        };
    } else {
        updateObj = {
            backup_1: backupOneTime,
            backup_2: backupTwoTime,
            backup_3: backupThreeTime,
            backup_calculated: backupCalculatedTime,
            heat_place: _.isNumber(place) && place > 0 ? place : null,
            eventtime: finalTime,
            timecode:
                // if DQ is set by DOL, set to DQ
                (place === "Q" && Number(INVERTED_TIMECODE_MAP.DQ)) ||
                // if final time is missing, keep current timecode or set to NT
                (!finalTime &&
                    (splash.timecode || Number(INVERTED_TIMECODE_MAP.NT))) ||
                // if final time is present and timecode is DQ or DFS, keep current timecode
                (["DQ", "DFS"].includes(TIMECODE_MAP[splash.timecode]) &&
                    splash.timecode) ||
                // if final time is present, unset timecode
                null,
            splittimes: splitTimes.join(","),
            splitdistance: splitDistance,
        };
    }

    return splash.copyWith(updateObj);
};

const orderTeamLanes = ({ lanes, lanePrefs = "" }) => {
    if (!lanePrefs) return lanes;

    const lanePrefsArray = lanePrefs.split(",").map(Number);
    const ordered = _.orderBy(lanes, [(lane) => lanePrefsArray.indexOf(lane)]);

    return ordered;
};

export const suggestHeatAndLane = ({
    meet,
    meetWrap,
    meetEvent,
    seedRules,
    team,
    squad = null,
    extraSplashes = [],
}) => {
    if (!team) return { heat: null, lane: null };

    const { is_intersquad: meetIsIntrasquad } = meet || {};
    const { lane_preferences, seed_preferences } = seedRules || {};
    const { eventgender, isDiving } = meetEvent || {};

    const basicTeamLanes = team.lanes || [];
    const teamGenderLanesMap = team.gender_lanes || {};
    const genderLanes = teamGenderLanesMap[eventgender] || [];
    const useGenderLanes = !_.isEmpty(teamGenderLanesMap);
    let teamLanes = orderTeamLanes({
        lanes: !useGenderLanes ? basicTeamLanes : genderLanes,
        lanePrefs: lane_preferences,
    });

    if (meetIsIntrasquad) {
        const teamSquadLanesMap = team.intrasquad_lanes || {};
        const squadLanesMap = squad ? teamSquadLanesMap[squad.id] || {} : {};
        const squadLanes =
            squadLanesMap.lanes && squadLanesMap.lanes.length
                ? squadLanesMap.lanes.split(",").map((lane) => Number(lane))
                : [];
        const squadGenderLanes =
            squadLanesMap.gender_lanes && squadLanesMap.gender_lanes.length
                ? squadLanesMap.gender_lanes
                      .split(",")
                      .map((genderLane) => {
                          if (genderLane.endsWith(eventgender)) {
                              return Number(genderLane.slice(0, -1));
                          }

                          return null;
                      })
                      .filter((x) => x)
                : [];
        const useSquadGenderLanes =
            squadLanesMap.gender_lanes && squadLanesMap.gender_lanes.length;

        teamLanes = orderTeamLanes({
            lanes: !useSquadGenderLanes ? squadLanes : squadGenderLanes,
            lanePrefs: lane_preferences,
        });
    }

    let heat = 0;
    let lane = 0;

    if (!isDiving && seed_preferences === "LB") {
        const eventSplashes = meetWrap.filterSplashes({
            meetEvent,
            isRecord: true,
        });

        const splashes = [...eventSplashes, ...extraSplashes];

        const heatSplashesMap = _.groupBy(splashes, (splash) => splash.heat);

        while (!lane && heat < 100) {
            heat += 1;
            const heatSplashes = heatSplashesMap[heat] || [];
            const usedLanes = heatSplashes.map((splash) => splash.lane);
            const freeLanes = _.difference(teamLanes, usedLanes);
            [lane] = freeLanes;
        }
    }

    if (!heat || !lane) {
        heat = null;
        lane = null;
    }

    return { heat, lane };
};

export const suggestAgeGroup = ({ meetEvent, ages }) => {
    const minAge = _.min(ages);
    const maxAge = _.max(ages);
    const sumAge = _.sum(ages);

    const agegroup = _.orderBy(meetEvent.agegroups, [
        "min_age",
        "max_age",
    ]).find((item) => {
        if (item.calculation_type === "S")
            return (
                (!item.min_age || item.min_age <= minAge) &&
                (!item.max_age || item.max_age >= maxAge)
            );
        if (item.calculation_type === "T")
            return (
                (!item.min_age || item.min_age <= sumAge) &&
                (!item.max_age || item.max_age >= sumAge)
            );
        return false;
    });

    return agegroup || meetEvent.agegroups[0];
};

export const isValidEvent = ({ meetEvent }) =>
    Boolean(
        meetEvent.id /* not unmatched */ &&
            meetEvent.eventnumber &&
            meetEvent.eventstroke &&
            meetEvent.eventdistance &&
            meetEvent.eventgender &&
            meetEvent.numlegs &&
            meetEvent.eventround &&
            (meetEvent.isDiving || meetEvent.eventcourse),
    );

export const getEventConstraints = ({
    team,
    squad = null,
    meetWrap,
    meetEvent,
    entryRules,
    canManageMeet,
    extraSplashes = [],
}) => {
    const {
        max_indrecords_per_team,
        max_relays_per_team,
        allow_exceed_athlete,
        allow_exceed_team,
    } = entryRules || {};

    let teamId = null;

    if (team) {
        if (canManageMeet) {
            teamId = team.orbitId;
        } else {
            teamId = team.id;
        }
    }

    let existingSplashes = meetWrap
        .filterSplashes({
            meetEvent,
            teamId,
            isRecord: true,
        })
        .filter((splash) => splash.isEntriesRound);

    if (squad) {
        existingSplashes = existingSplashes.filter(
            (splash) =>
                splash.extra_data &&
                splash.extra_data.intrasquad_id === squad.id,
        );
    }

    const newSplashes = extraSplashes.filter((splash) => splash.isEntriesRound);
    const splashes = [...existingSplashes, ...newSplashes];

    let indrecords = splashes.filter((splash) => splash.swimmer);
    if (allow_exceed_athlete)
        indrecords = indrecords.filter((splash) => !splash.exhibition);

    let relays = splashes.filter((splash) => !splash.swimmer);
    if (allow_exceed_team)
        relays = relays.filter((splash) => !splash.exhibition);

    const maxIndrecordsReached =
        max_indrecords_per_team && indrecords.length >= max_indrecords_per_team;
    const maxRelaysReached =
        max_relays_per_team && relays.length >= max_relays_per_team;

    return {
        maxIndrecordsReached,
        maxRelaysReached,
        allowIndrecords: !maxIndrecordsReached,
        allowRelays: !maxRelaysReached,
    };
};

export const getNextAvailableRelayName = ({
    meetWrap,
    meetEvent,
    team,
    squad,
    canManageMeet,
}) => {
    let names =
        team && meetWrap
            ? meetWrap
                  .filterSplashes({
                      teamId: canManageMeet ? team.orbitId : team.id,
                      meetEvent,
                  })
                  .map((splash) => splash.relayname)
                  .filter((x) => x)
            : [];

    if (squad) {
        names = meetWrap
            ? meetWrap
                  .filterSplashes({
                      squadId: squad.id,
                      meetEvent,
                  })
                  .map((splash) => splash.relayname)
                  .filter((x) => x)
            : [];
    }

    const usedNames = _.uniq(names);

    return "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
        .split("")
        .find((letter) => !usedNames.includes(letter));
};

export const getLegTimeFromRelaySplits = ({
    relay,
    legposition,
    canManageMeet,
}) => {
    let relaySplitTimes = [];

    if (canManageMeet) {
        relaySplitTimes =
            relay.split && relay.split.splittimes
                ? relay.split.splittimes.split(",")
                : [];
    } else {
        relaySplitTimes = relay.splittimes ? relay.splittimes.split(",") : [];
    }

    const { legSplitsCount } = relay;

    if (!legSplitsCount) return "";

    const prevTime =
        relaySplitTimes[(legposition - 1) * legSplitsCount - 1] || "";

    const cumulativeTime =
        relaySplitTimes[legposition * legSplitsCount - 1] || "";

    return subtractTime(cumulativeTime, prevTime);
};

export const getSwimmersLimitWarning = ({
    swimmer,
    meetWrap,
    entryRules,
    totalSwimmers,
    teamTotalSwimmers,
}) => {
    const { max_swimmers_per_meet, max_swimmers_per_team } = entryRules;

    if (
        !meetWrap.getSwimmerSplashes({
            swimmerId: swimmer.orbitId || swimmer.id,
        }).length
    ) {
        if (max_swimmers_per_meet && totalSwimmers >= max_swimmers_per_meet) {
            return "Number of participants in meet has been reached";
        }

        if (
            max_swimmers_per_team &&
            teamTotalSwimmers >= max_swimmers_per_team
        ) {
            return "Number of participants for this team has been reached";
        }
    }

    return null;
};

export const getSwimmerConstraints = ({
    swimmer,
    meetWrap,
    entryRules,
    excludeSplash,
    canManageMeet,
}) => {
    const {
        max_indrecords_per_athlete,
        max_relays_per_athlete,
        max_total_per_athlete,
        allow_exceed_athlete,
    } = entryRules || {};

    let splashes = meetWrap
        .getSwimmerSplashes({
            swimmerId: swimmer.orbitId || swimmer.id,
        })
        .filter((splash) => splash.isEntriesRound);

    if (excludeSplash) {
        if (canManageMeet) {
            splashes = splashes.filter(
                (splash) =>
                    splash.orbitId !== excludeSplash.orbitId &&
                    splash.relayOrbitId !== excludeSplash.orbitId,
            );
        } else {
            splashes = splashes.filter(
                (splash) =>
                    splash.id !== excludeSplash.id &&
                    splash.relay_id !== excludeSplash.id,
            );
        }
    }

    if (allow_exceed_athlete) {
        splashes = splashes.filter((splash) => !splash.exhibition);
    }

    const indrecords = splashes.filter((splash) => !splash.isLeg);
    const legs = splashes.filter((splash) => splash.isLeg);

    const maxIndrecordsReached =
        max_indrecords_per_athlete &&
        indrecords.length >= max_indrecords_per_athlete;
    const maxRelaysReached =
        max_relays_per_athlete && legs.length >= max_relays_per_athlete;
    const maxTotalReached =
        max_total_per_athlete && splashes.length >= max_total_per_athlete;

    return {
        maxIndrecordsReached,
        maxRelaysReached,
        maxTotalReached,
        allowIndrecords: !maxIndrecordsReached && !maxTotalReached,
        allowRelays: !maxRelaysReached && !maxTotalReached,
    };
};

export const getIndrecordLimitsWarning = ({
    splash,
    meetWrap,
    meetEvent,
    entryRules,
    canManageMeet,
    extraSplashes = [],
}) => {
    const reasons = [];
    const { swimmer, team } = splash;

    if (swimmer) {
        const swimmerConstraints = getSwimmerConstraints({
            swimmer,
            meetWrap,
            entryRules,
            canManageMeet,
        });

        if (!swimmerConstraints.allowIndrecords) {
            reasons.push("Max entries per swimmer has been reached");
        }
    }

    const eventConstraints = getEventConstraints({
        team,
        meetWrap,
        meetEvent,
        entryRules,
        canManageMeet,
        extraSplashes,
    });

    if (!eventConstraints.allowIndrecords) {
        reasons.push("Max entries per team has been reached");
    }

    return reasons.join(", ");
};

export const parseSelectedSwimmer = ({ swimmer }) => {
    if (!swimmer) return null;

    return {
        absolute_url: swimmer.absolute_url || null,
        age: swimmer.age || null,
        attending: swimmer.attending || false,
        dateofbirth: swimmer.dateofbirth || null,
        display_name: swimmer.display_name || null,
        first_name: swimmer.first_name || null,
        gender: swimmer.gender || null,
        gradhs: swimmer.gradhs || null,
        initials: swimmer.initials || null,
        id: swimmer.id || null,
        last_name: swimmer.last_name || null,
        location: swimmer.location || null,
        meet_age: swimmer.meet_age || null,
        meet_squad_id: swimmer.meet_squad_id || null,
        meet_team_abbr: swimmer.meet_team_abbr || null,
        meet_team_id: swimmer.meet_team_id || null,
        meet_team_name: swimmer.meet_team_name || null,
        name: swimmer.name || null,
        orbitId: swimmer.orbitId || null,
        picture_tiny: swimmer.picture_tiny || null,
        school_class: swimmer.school_class || null,
        season_best: swimmer.season_best || null,
        season_best_course: swimmer.season_best_course || null,
        season_best_id: swimmer.season_best_id || null,
        varsity: swimmer.varsity || "",
    };
};

export const canSwimmerEnterEvent = ({
    swimmer,
    meetEvent,
    isHighSchoolMeet,
    isEligible,
}) => {
    if (!swimmer || !meetEvent) return false;

    const {
        agegroups,
        eventgender,
        varsity: eventVarsity,
        isEntriesRound,
        isMixedGender,
    } = meetEvent || {};
    const { age, meet_age, gender, varsity: swimmerVarsity } = swimmer;

    const swimmerAge = meet_age || age;
    const conditions = [
        isValidEvent({ meetEvent }),
        isEntriesRound,
        isMixedGender || eventgender === gender,
    ];

    if (isEligible) {
        if (isHighSchoolMeet) {
            const isOpenEvent =
                !eventVarsity &&
                agegroups &&
                agegroups.some((ag) => ag.eventage === DEFAULT_EVENTAGE);
            const eventMatchesSwimmerVarsity = eventVarsity === swimmerVarsity;
            const fitsInEventHsAgeGroup =
                agegroups &&
                agegroups.some((ag) => {
                    if (!ag.hsclass) return false;

                    if (
                        ag.min_age &&
                        ag.max_age &&
                        ag.min_age <= swimmerAge &&
                        ag.max_age >= swimmerAge
                    ) {
                        return true;
                    }

                    if (!ag.max_age && ag.min_age && ag.min_age <= swimmerAge) {
                        return true;
                    }

                    if (!ag.min_age && ag.max_age && ag.max_age >= swimmerAge) {
                        return true;
                    }

                    return false;
                });

            conditions.push(
                isOpenEvent ||
                    eventMatchesSwimmerVarsity ||
                    fitsInEventHsAgeGroup,
            );
        } else {
            const fitsInEventAgeGroup =
                agegroups &&
                agegroups.some((ag) => {
                    if (ag.eventage === DEFAULT_EVENTAGE) return true;

                    if (
                        ag.min_age &&
                        ag.max_age &&
                        ag.min_age <= swimmerAge &&
                        ag.max_age >= swimmerAge
                    ) {
                        return true;
                    }

                    if (!ag.max_age && ag.min_age && ag.min_age <= swimmerAge) {
                        return true;
                    }

                    if (!ag.min_age && ag.max_age && ag.max_age >= swimmerAge) {
                        return true;
                    }

                    return false;
                });

            conditions.push(fitsInEventAgeGroup);
        }
    }

    return conditions.every(Boolean);
};

const getCourseSortWeight = ({ course, eventCourse }) => {
    if (course === eventCourse) return -1;
    return 0;
};

export const getFastestTime = ({
    meet,
    entryRules,
    fastest_times,
    meetEvent,
}) => {
    if (!fastest_times || !fastest_times.length) return null;

    const { valid_seedtime_required } = meet || {};
    const { cuts_courses } = entryRules || {};

    const {
        eventstroke,
        eventdistance,
        eventcourse,
        eventId,
        isRelay,
        isDiving,
    } = meetEvent || {};

    const cutCourses = cuts_courses
        ? cuts_courses.replace(" ", "").split(",")
        : [];

    const strokeSet = new Set([eventstroke]);
    const distanceSet = new Set([eventdistance]);
    const courseSet = new Set(
        cutCourses ? [eventcourse, ...cutCourses] : [eventcourse],
    );

    const hasAltEventTimes =
        valid_seedtime_required &&
        cuts_courses &&
        !isRelay &&
        !isDiving &&
        _.has(ALTERNATIVE_EVENT_ID_OPTIONS_MAP, eventId);

    if (hasAltEventTimes) {
        const options = ALTERNATIVE_EVENT_ID_OPTIONS_MAP[eventId];

        options.forEach((option) => {
            const [stroke, distance, course] = parseEventId({
                eventId: option.key,
            });

            strokeSet.add(stroke);
            distanceSet.add(Number(distance));
            courseSet.add(course);
        });
    }

    const strokes = [...strokeSet];
    const distances = [...distanceSet];
    const courses = [...courseSet];

    const fastestTimes = _.cloneDeep(fastest_times);

    fastestTimes.sort((splash) =>
        getCourseSortWeight({
            course: splash.eventcourse,
            eventCourse: eventcourse,
        }),
    );

    return fastestTimes.find(
        (splash) =>
            //
            // do not check splash.eventgender against meetEvent.eventgender
            // because we check swimmer.gender against meetEvent.eventgender
            //
            // (meetEvent.isMixedGender ||
            //     splash.eventgender === meetEvent.eventgender) &&
            //
            strokes.includes(splash.eventstroke) &&
            distances.includes(splash.eventdistance) &&
            (isDiving ||
                splash.meet_cut ||
                courses.includes(splash.eventcourse)),
    );
};

export const getQualifyingPeriod = ({ meet, canManageMeet }) => {
    const { valid_seedtime_required, qualifying_period } = meet;

    const startDate = dateFns.parse(
        (!valid_seedtime_required &&
            localStorage.getItem(
                canManageMeet
                    ? MEET_MANAGE_QUALIFYING_START_LOCAL_KEY
                    : MEET_COLLABORATE_QUALIFYING_START_LOCAL_KEY,
            )) ||
            (qualifying_period &&
                qualifying_period.length > 0 &&
                qualifying_period[0]) ||
            null,
    );

    const endDate = dateFns.parse(
        (!valid_seedtime_required &&
            localStorage.getItem(
                canManageMeet
                    ? MEET_MANAGE_QUALIFYING_END_LOCAL_KEY
                    : MEET_COLLABORATE_QUALIFYING_END_LOCAL_KEY,
            )) ||
            (qualifying_period &&
                qualifying_period.length > 1 &&
                qualifying_period[1]) ||
            null,
    );

    return [startDate, endDate];
};

export const getAgeGradeLabel = ({ swimmer, meet, season }) => {
    if (swimmer) {
        let value =
            Boolean(swimmer.meet_age) && `${swimmer.meet_age} years old`;

        if (meet && meet.orgcode === 9) {
            const currentSeasonYear = season
                ? Number(season.split("-")[1])
                : null;

            if (!currentSeasonYear) return null;

            const gradHSMap = {
                [(currentSeasonYear + 6).toString()]: "6th",
                [(currentSeasonYear + 5).toString()]: "7th",
                [(currentSeasonYear + 4).toString()]: "8th",
                [(currentSeasonYear + 3).toString()]: "FR",
                [(currentSeasonYear + 2).toString()]: "SO",
                [(currentSeasonYear + 1).toString()]: "JR",
                [currentSeasonYear.toString()]: "SR",
            };

            if (swimmer.gradhs) {
                value = gradHSMap[String(swimmer.gradhs)];
            }
        }

        return value;
    }

    return null;
};

export const getNextRound = ({ meetEvent, combinedRounds }) => {
    const eventRounds = _.orderBy(
        combinedRounds.filter(
            (item) => item.first_eventnumber === meetEvent.first_eventnumber,
        ),
        "roundorder",
    );

    return eventRounds.find(
        (item) => item.roundorder > meetEvent.roundorder && !item.isSwimoff,
    );
};

export const getPrevRound = ({ meetEvent, combinedRounds }) => {
    const eventRounds = _.orderBy(
        combinedRounds.filter(
            (item) => item.first_eventnumber === meetEvent.first_eventnumber,
        ),
        "roundorder",
        "desc",
    );

    return eventRounds.find(
        (item) => item.roundorder < meetEvent.roundorder && !item.isSwimOff,
    );
};

export const getRelayLimitsWarning = ({
    splash,
    entryRules,
    meetEvent,
    meetWrap,
    canManageMeet,
}) => {
    const reasons = [];
    const { team } = splash;

    const eventConstraints = getEventConstraints({
        team,
        meetWrap,
        meetEvent,
        entryRules,
        canManageMeet,
    });

    if (!eventConstraints.allowRelays) {
        reasons.push("Max entries per team has been reached");
    }

    return reasons.join(", ");
};

export const showEventBench = ({
    entryRules,
    meetEvent,
    eventManageableTeams,
}) =>
    entryRules &&
    !entryRules.entries_closed &&
    meetEvent.isEntriesRound &&
    isValidEvent({ meetEvent }) &&
    eventManageableTeams.length > 0;

const getSplashSeedId = ({ splash, canManageMeet }) => {
    if (!splash) return null;

    if (canManageMeet) {
        if (splash.extra_data && splash.extra_data.seed_id) {
            return splash.extra_data.seed_id;
        }

        return null;
    }

    return splash.seed_id;
};

export const getMeetWrapSeedIds = ({ meetWrap, canManageMeet }) => {
    return new Set(
        meetWrap && meetWrap.splashes
            ? meetWrap.splashes
                  .map((splash) => getSplashSeedId({ splash, canManageMeet }))
                  .filter((x) => x)
            : [],
    );
};

export const serializeCtsData = (ctsData) => {
    const { meet, header, lanes } = ctsData;
    return {
        ...header,
        meet_date_seconds: meet.date_seconds,
        meet_date_minutes: meet.date_minutes,
        meet_date_hours: meet.date_hours,
        meet_date_weekday: meet.date_weekday,
        meet_date_month: meet.date_month,
        meet_date_day: meet.date_day,
        meet_date_year: meet.date_year,
        meet_date_long_year: meet.date_long_year,
        include_backup: Boolean(header.include_backup),
        include_splits: Boolean(header.include_splits),
        include_individual_buttons: Boolean(header.include_individual_buttons),
        include_relay_judging: Boolean(header.include_relay_judging),
        lanes: lanes.map((ctsLane, index) => ({
            ...ctsLane,
            lane: index + 1,
            split_times: (ctsLane.split_times || []).join(","),
            individual_button_times: (
                ctsLane.individual_button_times || []
            ).join(","),
        })),
    };
};

export const parseCtsDate = (ctsHeader) =>
    new Date(
        ctsHeader.date_long_year,
        ctsHeader.date_month - 1,
        ctsHeader.date_day,
        ctsHeader.date_hours,
        ctsHeader.date_minutes,
        ctsHeader.date_seconds,
    );

export const orderCtsRaces = (ctsRaces) =>
    _.orderBy(
        ctsRaces,
        [
            (item) => parseCtsDate(item),
            (item) => item.race_number,
            (item) => item.event,
            (item) => item.heat,
        ],
        ["desc", "desc", "desc", "desc"],
    );

export const passCtsRaceToHeat = ({
    ctsRace,
    meetWrap,
    meetEvent,
    heat,
    canManageMeet,
}) => {
    const ctsLaneByNumber = _.fromPairs(
        (ctsRace.lanes || []).map((ctsLane) => [ctsLane.lane, ctsLane]),
    );

    const splashes = meetWrap
        .filterSplashes({ meetEvent, heat, isRecord: true })
        .filter((splash) => splash.can_manage_splash);

    return _.flatten(
        splashes
            .map((splash) => {
                const { lane } = splash;

                if (!lane) return null;

                const ctsLane = ctsLaneByNumber[lane];

                if (!ctsLane) {
                    return [
                        splash.copyWith({
                            timecode:
                                splash.timecode ||
                                Number(INVERTED_TIMECODE_MAP.NT),
                        }),
                    ];
                }

                const updatedSplash = passCtsLaneToSplash({
                    ctsLane,
                    splash,
                    canManageMeet,
                });

                if (!updatedSplash.eventcourse) {
                    updatedSplash.eventcourse = meetEvent.eventcourse;
                }

                const updatedLegs = [];

                if (updatedSplash.isRelay) {
                    const legs = meetWrap.getRelayLegs({
                        relay: updatedSplash,
                    });

                    updatedLegs.push(
                        ...legs
                            .map((leg) => {
                                const eventtime = getLegTimeFromRelaySplits({
                                    relay: updatedSplash,
                                    legposition: leg.legposition,
                                    canManageMeet,
                                });
                                return (
                                    eventtime &&
                                    leg.copyWith({
                                        eventtime,
                                        eventcourse: updatedSplash.eventcourse,
                                    })
                                );
                            })
                            .filter(Boolean),
                    );
                }

                return [updatedSplash, ...updatedLegs];
            })
            .filter(Boolean),
    );
};

export const passSstRaceToHeat = ({
    sstRace,
    meetWrap,
    meetEvent,
    heat,
    canManageMeet,
}) => {
    const sstLanes = sstRace.lanes;

    const splashes = meetWrap
        .filterSplashes({ meetEvent, heat, isRecord: true })
        .filter((splash) => splash.can_manage_splash);

    return _.flatten(
        splashes
            .map((splash) => {
                const { lane } = splash;

                if (!lane) return null;

                const sstLane = sstLanes[lane - 1];

                if (!sstLane) {
                    return [
                        splash.copyWith({
                            timecode:
                                splash.timecode ||
                                Number(INVERTED_TIMECODE_MAP.NT),
                        }),
                    ];
                }

                const updatedSplash = passSstLaneToSplash({
                    sstLane,
                    splash,
                    canManageMeet,
                });

                if (!updatedSplash.eventcourse) {
                    updatedSplash.eventcourse = meetEvent.eventcourse;
                }

                const updatedLegs = [];

                if (updatedSplash.isRelay) {
                    const legs = meetWrap.getRelayLegs({
                        relay: updatedSplash,
                    });

                    updatedLegs.push(
                        ...legs
                            .map((leg) => {
                                const eventtime = getLegTimeFromRelaySplits({
                                    relay: updatedSplash,
                                    legposition: leg.legposition,
                                    canManageMeet,
                                });
                                return (
                                    eventtime &&
                                    leg.copyWith({
                                        eventtime,
                                        eventcourse: updatedSplash.eventcourse,
                                    })
                                );
                            })
                            .filter(Boolean),
                    );
                }

                return [updatedSplash, ...updatedLegs];
            })
            .filter(Boolean),
    );
};

export const orderDakRaces = (dakRaces) =>
    _.orderBy(
        dakRaces,
        [(item) => item.number, (item) => item.event, (item) => item.heat],
        ["desc", "desc", "desc"],
    );

export const passDakRaceToHeat = ({
    dakRace,
    meetWrap,
    meetEvent,
    heat,
    canManageMeet,
}) => {
    const dakLaneByNumber = _.fromPairs(
        (dakRace.lanes || []).map((dakLane) => [dakLane.lane, dakLane]),
    );

    const splashes = meetWrap
        .filterSplashes({ meetEvent, heat, isRecord: true })
        .filter((splash) => splash.can_manage_splash);

    return _.flatten(
        splashes
            .map((splash) => {
                const { lane } = splash;

                if (!lane) return null;

                const dakLane = dakLaneByNumber[lane];

                if (!dakLane) {
                    return [
                        splash.copyWith({
                            timecode:
                                splash.timecode ||
                                Number(INVERTED_TIMECODE_MAP.NT),
                        }),
                    ];
                }

                const updatedSplash = passDakLaneToSplash({
                    dakLane,
                    splash,
                    canManageMeet,
                });

                if (!updatedSplash.eventcourse) {
                    updatedSplash.eventcourse = meetEvent.eventcourse;
                }

                const updatedLegs = [];

                if (updatedSplash.isRelay) {
                    const legs = meetWrap.getRelayLegs({
                        relay: updatedSplash,
                    });

                    updatedLegs.push(
                        ...legs
                            .map((leg) => {
                                const eventtime = getLegTimeFromRelaySplits({
                                    relay: updatedSplash,
                                    legposition: leg.legposition,
                                    canManageMeet,
                                });
                                return (
                                    eventtime &&
                                    leg.copyWith({
                                        eventtime,
                                        eventcourse: updatedSplash.eventcourse,
                                    })
                                );
                            })
                            .filter(Boolean),
                    );
                }

                return [updatedSplash, ...updatedLegs];
            })
            .filter(Boolean),
    );
};

export const passTdrRaceToHeat = ({
    tdrRace,
    meetWrap,
    meetEvent,
    heat,
    canManageMeet,
}) => {
    const tdrLanes = tdrRace.lanes;

    const splashes = meetWrap
        .filterSplashes({ meetEvent, heat, isRecord: true })
        .filter((splash) => splash.can_manage_splash);

    return _.flatten(
        splashes
            .map((splash) => {
                const { lane } = splash;

                if (!lane) return null;

                const tdrLane = tdrLanes[lane - 1];

                if (!tdrLane || tdrLane.place === 0) {
                    return [
                        splash.copyWith({
                            timecode:
                                splash.timecode ||
                                Number(INVERTED_TIMECODE_MAP.NT),
                        }),
                    ];
                }

                const updatedSplash = passTdrLaneToSplash({
                    tdrLane,
                    splash,
                    canManageMeet,
                });

                if (!updatedSplash.eventcourse) {
                    updatedSplash.eventcourse = meetEvent.eventcourse;
                }

                const updatedLegs = [];

                if (updatedSplash.isRelay) {
                    const legs = meetWrap.getRelayLegs({
                        relay: updatedSplash,
                    });

                    updatedLegs.push(
                        ...legs
                            .map((leg) => {
                                const eventtime = getLegTimeFromRelaySplits({
                                    relay: updatedSplash,
                                    legposition: leg.legposition,
                                    canManageMeet,
                                });
                                return (
                                    eventtime &&
                                    leg.copyWith({
                                        eventtime,
                                        eventcourse: updatedSplash.eventcourse,
                                    })
                                );
                            })
                            .filter(Boolean),
                    );
                }

                return [updatedSplash, ...updatedLegs];
            })
            .filter(Boolean),
    );
};

export const passDolRaceToHeat = ({
    dolRace,
    meetWrap,
    meetEvent,
    heat,
    canManageMeet,
}) => {
    const dolLanes = dolRace.lanes;

    const splashes = meetWrap
        .filterSplashes({ meetEvent, heat, isRecord: true })
        .filter((splash) => splash.can_manage_splash);

    return _.flatten(
        splashes
            .map((splash) => {
                const { lane } = splash;

                if (!lane) return null;

                const dolLane = dolLanes[lane - 1];

                if (!dolLane || dolLane.place === 0) {
                    return [
                        splash.copyWith({
                            timecode:
                                splash.timecode ||
                                Number(INVERTED_TIMECODE_MAP.NT),
                        }),
                    ];
                }

                const updatedSplash = passDolLaneToSplash({
                    dolLane,
                    splash,
                    canManageMeet,
                });

                if (!updatedSplash.eventcourse) {
                    updatedSplash.eventcourse = meetEvent.eventcourse;
                }

                const updatedLegs = [];

                if (updatedSplash.isRelay) {
                    const legs = meetWrap.getRelayLegs({
                        relay: updatedSplash,
                    });

                    updatedLegs.push(
                        ...legs
                            .map((leg) => {
                                const eventtime = getLegTimeFromRelaySplits({
                                    relay: updatedSplash,
                                    legposition: leg.legposition,
                                    canManageMeet,
                                });
                                return (
                                    eventtime &&
                                    leg.copyWith({
                                        eventtime,
                                        eventcourse: updatedSplash.eventcourse,
                                    })
                                );
                            })
                            .filter(Boolean),
                    );
                }

                return [updatedSplash, ...updatedLegs];
            })
            .filter(Boolean),
    );
};

export const getAgeVarsityName = ({
    eventage,
    varsity,
    ageGroupNameMap = {},
}) => {
    const displayAge =
        eventage &&
        eventage !== DEFAULT_EVENTAGE &&
        (ageGroupNameMap[eventage] ||
            getEventAgeName({ eventage, abbr: true }));

    const names = [displayAge, varsity && getVarsityName(varsity)].filter(
        Boolean,
    );

    return names.length ? names.join(" ") : "Open";
};

export const getCtsRaceHash = ({ ctsRace }) =>
    objectHash({
        meet_date_seconds: ctsRace.meet_date_seconds,
        meet_date_minutes: ctsRace.meet_date_minutes,
        meet_date_hours: ctsRace.meet_date_hours,
        meet_date_month: ctsRace.meet_date_month,
        meet_date_day: ctsRace.meet_date_day,
        meet_date_long_year: ctsRace.meet_date_long_year,
        event: ctsRace.event,
        heat: ctsRace.heat,
        race_number: ctsRace.race_number,
        date_seconds: ctsRace.date_seconds,
        date_minutes: ctsRace.date_minutes,
        date_hours: ctsRace.date_hours,
        date_month: ctsRace.date_month,
        date_day: ctsRace.date_day,
        date_long_year: ctsRace.date_long_year,
    });

export const getSstRaceHash = ({ sstRace }) =>
    objectHash({ filename: sstRace.filename, sha1: sstRace.sha1 });

export const getDakRaceHash = ({ dakRace }) =>
    objectHash({
        event: dakRace.event,
        heat: dakRace.heat,
        number: dakRace.number,
        round: dakRace.round,
    });

export const getTdrRaceHash = ({ tdrRace }) =>
    objectHash({ filename: tdrRace.filename, sha1: tdrRace.sha1 });

export const getDolRaceHash = ({ dolRace }) =>
    objectHash({ filename: dolRace.filename, sha1: dolRace.sha1 });

export const hasAssignedLanes = ({ meet, teams, seedPreferences }) => {
    const { is_intersquad: meetIsIntrasquad } = meet || {};

    if (teams && seedPreferences === "LB") {
        if (meetIsIntrasquad) {
            const hasIntrasquadLanes = teams.some(
                (team) => !_.isEmpty(team.intrasquad_lanes),
            );

            return hasIntrasquadLanes;
        }

        const lanes = _.flatten(teams.map((team) => team.lanes));
        const hasGenderLanes = teams.some(
            (team) => !_.isEmpty(team.gender_lanes),
        );

        return Boolean(lanes.length) || hasGenderLanes;
    }

    return true;
};

const getEventTimesLocalLayout = (localLayoutKey) => {
    return localStorage.getItem(localLayoutKey);
};

export const setEventTimesLocalLayout = (localLayoutKey, layout) => {
    localStorage.setItem(localLayoutKey, layout);
};

export const getRelayModalLocalSortBy = (key) => {
    return localStorage.getItem(key);
};

export const setRelayModalLocalSortBy = (key, value) => {
    localStorage.setItem(key, value);
};

export const getEventTimesInitialLayout = ({ meetEvent, localLayoutKey }) => {
    if (!meetEvent || !localLayoutKey) return EVENT_TIMES_LAYOUTS.entries;

    const localLayout = getEventTimesLocalLayout(localLayoutKey);
    let layoutsArray = _.values(EVENT_TIMES_LAYOUTS);

    if (meetEvent.isDiving) {
        layoutsArray = _.values(EVENT_TIMES_LAYOUTS).filter(
            (layout) => layout !== "heats",
        );
    }

    return layoutsArray.includes(localLayout)
        ? localLayout
        : EVENT_TIMES_LAYOUTS.entries;
};

export const getIsIndexedDBSupported = () => {
    return new Promise((resolve) => {
        if (!window.indexedDB) {
            resolve(false);
            return;
        }

        try {
            const dbName = "testDB";
            const request = window.indexedDB.open(dbName, 1);

            request.onerror = () => {
                resolve(false);
            };

            request.onsuccess = (event) => {
                const db = event.target.result;

                db.close();

                window.indexedDB.deleteDatabase(dbName);

                resolve(true);
            };
        } catch (error) {
            resolve(false);
        }
    });
};

export const validateEntry = ({
    splash,
    meetEvent,
    meet,
    meetWrap,
    entryRules,
    teamTotalSwimmers,
    totalSwimmers,
    canManageMeet = false,
    extraSplashes = [],
    isBulk = false,
    isFromModal = true,
    changeSnackbarProps = () => {},
    changeConfirmEntry = () => {},
    changeEntryWarning = () => {},
}) => {
    if (!splash || !meetEvent || !meet || !meetWrap || !entryRules) {
        return false;
    }

    const { valid_seedtime_required } = meet;
    const { allow_exceed_athlete, allow_bonus, allow_no_time } = entryRules;

    const participates = meetWrap.hasSwimmerInEvent({
        meetEvent,
        swimmer: splash.swimmer,
    });

    if (participates) {
        changeSnackbarProps({
            type: "warning",
            message: `${
                splash.swimmer ? splash.swimmer.name : "Swimmer"
            } already participates in event`,
        });
        return false;
    }

    const timeWarning =
        valid_seedtime_required &&
        !allow_no_time &&
        !splash.seedtime &&
        !allow_bonus &&
        "Valid time is required";

    if (timeWarning) {
        changeSnackbarProps({ type: "warning", message: timeWarning });
        return false;
    }

    const swimmersLimitWarning = getSwimmersLimitWarning({
        entryRules,
        meetWrap,
        swimmer: splash.swimmer,
        totalSwimmers,
        teamTotalSwimmers,
    });

    if (swimmersLimitWarning) {
        changeSnackbarProps({
            type: "warning",
            message: swimmersLimitWarning,
        });
        return false;
    }

    const limitsWarning = getIndrecordLimitsWarning({
        splash,
        meetEvent,
        entryRules,
        meetWrap,
        canManageMeet,
        extraSplashes,
    });

    if (limitsWarning) {
        if (!allow_exceed_athlete) {
            changeSnackbarProps({ type: "warning", message: limitsWarning });
            return false;
        }

        if (!isBulk && allow_exceed_athlete === ALLOW_EXCEED_WARNING) {
            changeConfirmEntry({
                splash,
                meetEvent,
                isRelay: false,
                isFromModal,
            });
            changeEntryWarning(limitsWarning);
            return false;
        }

        // eslint-disable-next-line no-param-reassign
        splash.exhibition = true;
    }

    return true;
};

export const getPadsFromLane = ({
    ctsLane,
    sstLane,
    dakLane,
    tdrLane,
    dolLane,
}) => {
    if (!ctsLane && !sstLane && !dakLane && !tdrLane && !dolLane) {
        return { count: 0, times: [] };
    }

    if (ctsLane) {
        const { split_times, final_time } = ctsLane;

        const splitTimes = split_times
            ? split_times.split(",").map(Number)
            : [];
        const allPads = [...splitTimes, final_time];
        const padTimes = allPads.map(parseCtsTime);

        return { count: padTimes.length, times: padTimes };
    }

    if (sstLane) {
        const { splits } = sstLane;

        return { count: splits.length, times: splits };
    }

    if (dakLane) {
        const { split_times } = dakLane;

        const splitTimes = split_times ? split_times.split(",") : [];
        const parsedSplitTimes = splitTimes.map(Number);
        const padTimes = parsedSplitTimes.map(parseDakTime);

        return { count: padTimes.length, times: padTimes };
    }

    if (tdrLane) {
        const { splits } = tdrLane;
        const times =
            splits && splits.length
                ? splits.map((split) => swimToDecimal(split.time))
                : [];

        return { count: splits.length, times };
    }

    if (dolLane) {
        const { splits } = dolLane;
        const times =
            splits && splits.length ? splits.map((split) => split.time) : [];

        return { count: splits.length, times };
    }

    return { count: 0, times: [] };
};

export const checkIsPhoneValid = ({
    phone,
    country,
    useCountryCode = false,
}) => {
    return useCountryCode
        ? isValidPhoneNumber(phone, country.countryCode.toUpperCase())
        : isValidPhoneNumber(phone, country.iso2.toUpperCase());
};

export const combinations = ({ array, size }) => {
    if (!array) return [];
    if (!size) return [];
    if (size === 1) return array.map((item) => [item]);

    const result = [];

    function combine(newArray, i) {
        if (newArray.length === size) {
            result.push(newArray);
            return;
        }

        if (i + 1 > array.length) return;

        combine(newArray.concat(array[i]), i + 1);
        combine(newArray, i + 1);
    }

    combine([], 0);

    return result;
};

export const getTeamStatusColor = (status) =>
    ({
        no_entries: "c-label c-label--outline c-label--neutral",
        entries_in_progress: "c-label c-label--outline c-label--warning",
        entries_submitted: "c-label c-label--outline c-label--success",
    })[status] || "c-label c-label--outline c-label--neutral";

export const getTeamStatusTitle = (status) =>
    ({
        no_entries: "No entries",
        entries_in_progress: "In progress",
        entries_submitted: "Submitted",
    })[status] || status;

export const getSwimmersBasedOnTeamMeetStatus = ({ teams, meetWrap }) => {
    if (!teams) return [];

    const { swimmers } = meetWrap || {};

    if (!swimmers || !swimmers.length) return [];

    const teamIds = teams.map((team) => team.id);
    const filteredSwimmers = swimmers.filter((swimmer) =>
        teamIds.includes(swimmer.meet_team_id),
    );

    return filteredSwimmers;
};

export const getCoachRostersBasedOnTeamMeetStatus = ({
    teams,
    coachRoster,
}) => {
    if (!teams) return [];

    if (!coachRoster || !coachRoster.length) return [];

    const teamIds = teams.map((team) => team.id);
    const filteredCoachRosters = coachRoster.filter((coach) =>
        teamIds.includes(coach.team_id),
    );

    return filteredCoachRosters;
};

export const getSplashesBasedOnTeamMeetStatus = ({
    teams,
    meetWrap,
    splashType,
    canManageMeet,
}) => {
    if (!teams) return [];

    const { splashes } = meetWrap || {};

    if (!splashes || !splashes.length) return [];

    const teamIds = teams.map((team) =>
        canManageMeet ? team.orbitId : team.id,
    );
    const filteredSplashes = _.compact(
        splashes.filter((splash) => {
            const splashTeamId = canManageMeet
                ? splash.teamOrbitId
                : splash.team_id;
            if (splashType === "individual") {
                if (splash.numlegs <= 1 && teamIds.includes(splashTeamId)) {
                    return splash;
                }
            }
            if (splashType === "relay") {
                if (splash.numlegs > 1 && teamIds.includes(splashTeamId)) {
                    return splash;
                }
            }

            return null;
        }),
    );

    return filteredSplashes;
};

export const parseOrbitError = ({ error }) => {
    if (!error) return error;

    const errorMessage = error.message || "";
    const errorDetails =
        error.data && error.data.errors && error.data.errors.length
            ? error.data.errors
                  .map((reason) => (reason ? reason.detail : ""))
                  .filter((x) => x)
            : [];
    const errorReasons =
        errorDetails && errorDetails.length ? errorDetails.join(" | ") : "";

    if (!errorMessage && !errorReasons) return error;

    const separator = errorMessage && errorReasons ? " => " : "";

    return new Error(`${errorMessage}${separator}${errorReasons}`);
};

const isSplashAtPlace = ({ meetWrap, splash, place }) => {
    if (!meetWrap || !splash || !place) return false;

    if (splash.isLeg) {
        const splashRelay = meetWrap.getLegRelay({ leg: splash });

        if (
            !splash.isRelayLeg ||
            splash.isFlagged ||
            splash.isAlternate ||
            !splash.isFinal ||
            !splashRelay ||
            !splashRelay.pointsscored
        ) {
            return false;
        }

        return (
            splashRelay.place === String(place) ||
            splashRelay.place.startsWith(`${place},`) ||
            splashRelay.place.endsWith(`,${place}`) ||
            splashRelay.place.includes(`,${place},`)
        );
    }

    if (!splash.isFinal || !splash.isParent || !splash.pointsscored) {
        return false;
    }

    return (
        splash.place === String(place) ||
        splash.place.startsWith(`${place},`) ||
        splash.place.endsWith(`,${place}`) ||
        splash.place.includes(`,${place},`)
    );
};

export const getMeetTopSwimmers = ({
    canManageMeet,
    meet,
    meetWrap,
    selectedGender,
    selectedAgeGroup,
}) => {
    if (!meet || !meetWrap) return [];

    let filteredSplashes = meetWrap.splashes.filter((splash) => splash.swimmer);

    if (selectedGender && !["C", "X"].includes(selectedGender)) {
        filteredSplashes = filteredSplashes.filter(
            (splash) => splash.eventgender === selectedGender,
        );
    }

    if (
        selectedAgeGroup &&
        selectedAgeGroup.eventage !== DEFAULT_TIMES_AGE_GROUP.eventage
    ) {
        const isUsaPrep = meet && meet.country === "USA" && meet.orgcode === 9;

        if (isUsaPrep && !selectedAgeGroup.eventage) {
            if (canManageMeet) {
                filteredSplashes = filteredSplashes.filter(
                    (splash) =>
                        splash.extra_data.varsity === selectedAgeGroup.varsity,
                );
            } else {
                filteredSplashes = filteredSplashes.filter(
                    (splash) => splash.varsity === selectedAgeGroup.varsity,
                );
            }
        } else {
            filteredSplashes = filteredSplashes.filter((splash) =>
                splash.eventage.includes(selectedAgeGroup.eventage),
            );
        }
    }

    const swimmerScores = filteredSplashes.reduce((acc, splash) => {
        const { id, orbitId } = splash.swimmer || {};
        const swimmerId = canManageMeet ? orbitId : id;
        let meetPerf = 0;

        if (splash.isIndRecord && !splash.isFlagged) {
            meetPerf =
                meet.pointsystem_display_name === "FINA"
                    ? Number(splash.fina_points)
                    : Number(splash.pointvalue);
        }

        const score =
            splash.isIndRecord && !splash.isFlagged && splash.pointsscored
                ? Number(splash.pointsscored)
                : 0;

        if (!acc[swimmerId]) {
            acc[swimmerId] = {
                ...splash.swimmer,
                team: splash.team,
                meetPerf: meetPerf || 0,
                score: 0,
                gold: 0,
                silver: 0,
                bronze: 0,
            };
        }

        acc[swimmerId].score += score;
        acc[swimmerId].meetPerf = Math.max(
            acc[swimmerId].meetPerf,
            meetPerf || 0,
        );

        if (isSplashAtPlace({ meetWrap, splash, place: 1 })) {
            acc[swimmerId].gold += 1;
        }
        if (isSplashAtPlace({ meetWrap, splash, place: 2 })) {
            acc[swimmerId].silver += 1;
        }
        if (isSplashAtPlace({ meetWrap, splash, place: 3 })) {
            acc[swimmerId].bronze += 1;
        }

        return acc;
    }, {});

    const swimmers = Object.values(swimmerScores);

    // Sort swimmers by meetPerf and then by totalScore in case of a tie
    swimmers.sort((a, b) => {
        if (b.score !== a.score) return b.score - a.score;

        return b.meetPerf - a.meetPerf;
    });

    return swimmers;
};

export const getMeetTopSwims = ({
    canManageMeet,
    meet,
    meetWrap,
    selectedGender,
    selectedAgeGroup,
}) => {
    if (!meet || !meetWrap) return [];

    let filteredSplashes = meetWrap.splashes.filter(
        (splash) =>
            splash.isIndRecord &&
            !splash.isDiving &&
            !splash.isFlagged &&
            splash.swimmer,
    );

    if (selectedGender && !["C", "X"].includes(selectedGender)) {
        filteredSplashes = filteredSplashes.filter(
            (splash) => splash.eventgender === selectedGender,
        );
    }

    if (
        selectedAgeGroup &&
        selectedAgeGroup.eventage !== DEFAULT_TIMES_AGE_GROUP.eventage
    ) {
        const isUsaPrep = meet && meet.country === "USA" && meet.orgcode === 9;

        if (isUsaPrep && !selectedAgeGroup.eventage) {
            if (canManageMeet) {
                filteredSplashes = filteredSplashes.filter(
                    (splash) =>
                        splash.extra_data.varsity === selectedAgeGroup.varsity,
                );
            } else {
                filteredSplashes = filteredSplashes.filter(
                    (splash) => splash.varsity === selectedAgeGroup.varsity,
                );
            }
        } else {
            filteredSplashes = filteredSplashes.filter((splash) =>
                splash.eventage.includes(selectedAgeGroup.eventage),
            );
        }
    }

    return filteredSplashes.sort((a, b) => {
        const pointComparison =
            meet.pointsystem_display_name === "FINA"
                ? (Number(b.fina_points) || 0) - (Number(a.fina_points) || 0)
                : (Number(b.pointvalue) || 0) - (Number(a.pointvalue) || 0);

        if (pointComparison !== 0) return pointComparison;

        const timeComparison = new Date(a.eventtime) - new Date(b.eventtime);

        if (timeComparison !== 0) return timeComparison;

        return 0;
    });
};

export const getSplashGenderOptions = ({ meet, splashes }) => {
    if (!splashes || !splashes.length) return [];

    const { country, orgcode, is_dual_score: isDualMeet } = meet || {};

    const splashGenderSet = new Set(
        splashes
            .map((splash) => {
                if (!splash.pointsscored) return null;

                return splash.eventgender;
            })
            .filter((x) => x),
    );

    const isUsaPrep = country === "USA" && orgcode === 9;

    const hideCombinedGender =
        isDualMeet ||
        (country === "USA" && [3, 9].includes(orgcode)) ||
        !splashGenderSet.size;

    const splashGenderOptions = _.orderBy(
        [...splashGenderSet]
            .map((gender) => {
                let genders = GENDER_OPTIONS;

                if (isUsaPrep) {
                    genders = [...genders, X_GENDER_OPTION];
                }

                const genderOption = genders.find(
                    (option) => option.key === gender,
                );

                if (!genderOption) return null;

                return genderOption;
            })
            .filter((x) => x),
        "key",
    ).reverse();

    return !hideCombinedGender
        ? [...splashGenderOptions, C_GENDER_OPTION_ALT]
        : splashGenderOptions;
};

export const getSelectedSceneFilters = ({
    activeSession,
    selectedScene,
    showHeatTeam,
    selectedGender,
    selectedEvent,
    selectedAgeGroup,
    showResultsTeam,
}) => {
    if (!selectedScene) return null;

    const activeSessionNumber =
        activeSession && activeSession.number ? activeSession.number : null;

    if (selectedScene.id === DEFAULT_SCOREBOARD_SCENES[0].id) {
        return { activeSessionNumber, showHeatTeam };
    }

    if (selectedScene.id === DEFAULT_SCOREBOARD_SCENES[1].id) {
        return { activeSessionNumber, selectedGender };
    }

    if (selectedScene.id === DEFAULT_SCOREBOARD_SCENES[2].id) {
        return {
            activeSessionNumber,
            selectedEvent,
            selectedAgeGroup,
            showResultsTeam,
        };
    }

    return null;
};

export const getTeamRecords = ({
    canManageMeet,
    meet,
    team,
    meetWrap,
    selectedGender,
    selectedAgeGroup,
}) => {
    if (!meet || !team || !meetWrap) return [];

    const isUsaPrep = meet.country === "USA" && meet.orgcode === 9;

    const teamSplashes = meetWrap
        .filterSplashes({
            teamId: canManageMeet ? team.orbitId : team.id,
        })
        .filter((splash) => {
            if (!selectedGender || selectedGender === "C") return splash;
            return splash.eventgender === selectedGender;
        })
        .filter((splash) => {
            if (
                !selectedAgeGroup ||
                selectedAgeGroup.eventage === DEFAULT_TIMES_AGE_GROUP.eventage
            ) {
                return splash;
            }

            if (isUsaPrep && !selectedAgeGroup.eventage) {
                if (canManageMeet) {
                    return (
                        splash.extra_data.varsity === selectedAgeGroup.varsity
                    );
                }

                return splash.varsity === selectedAgeGroup.varsity;
            }

            return splash.eventage.includes(selectedAgeGroup.eventage);
        });

    const teamRecords = teamSplashes.filter((x) => !x.isLeg && !x.isUnattached);

    return { splashes: teamSplashes, records: teamRecords };
};

const buildEventId = ({ stroke, distance, course = "", addCourse = true }) => {
    if (course && addCourse) return [stroke, distance, course].join("");

    return [stroke, distance].join("");
};

export const convertDistance = ({
    toCourse,
    fromCourse,
    fromStroke,
    fromDistance,
}) => {
    if (toCourse === fromCourse) {
        return {
            newCourse: fromCourse,
            newStroke: fromStroke,
            newDistance: fromDistance,
        };
    }

    const oldUniqueEventId = buildEventId({
        stroke: fromStroke,
        distance: fromDistance,
        course: fromCourse,
    });
    const courseDistanceMap = DISTANCE_CHANGE_MAP[toCourse] || {};
    const newDistance = courseDistanceMap[oldUniqueEventId] || fromDistance;

    return { newCourse: toCourse, newStroke: fromStroke, newDistance };
};
