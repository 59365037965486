import React from "react";
import { useSelector } from "react-redux";

function TopSwims() {
    const meetId = useSelector((state) => state.meetId);
    const pointsystem = useSelector((state) => state.pointsystem);
    const pointsystemTitle = useSelector((state) => state.pointsystemTitle);
    const pointsystemDisplayName = useSelector(
        (state) => state.pointsystemDisplayName,
    );
    const topSwims = useSelector((state) => state.topSwims);
    const showMoreSwims = useSelector((state) => state.showMoreSwims);

    function renderMore() {
        if (!showMoreSwims) return null;

        return (
            <caption className="c-table-clean__caption c-table-clean__caption--bottom">
                <a
                    className="c-more c-more--small"
                    href={`/results/${meetId}/topswims/?${window.location.search}`}
                >
                    More
                </a>
            </caption>
        );
    }

    function renderHead() {
        return (
            <thead>
                <tr>
                    <th colSpan="2">Name</th>
                    <th>Team</th>
                    <th>Event</th>
                    <th className="c-table-clean__col-fit u-text-end">Time</th>
                    <th className="c-table-clean__col-fit u-text-end">Score</th>
                    <th
                        className="c-table-clean__col-fit u-text-end"
                        title={pointsystemTitle}
                    >
                        {pointsystemDisplayName}
                    </th>
                </tr>
            </thead>
        );
    }

    function renderBody() {
        return (
            <tbody>
                {topSwims.map((splash, index) => (
                    <tr key={splash.id}>
                        <td className="c-table-clean__col-fit u-pr0 u-text-center">
                            {index + 1}
                        </td>
                        <td>
                            <a
                                className="u-text-semi"
                                href={`${splash.meet_display_url}?${window.location.search}`}
                            >
                                {splash.display_name}
                            </a>
                        </td>
                        <td className="u-text-truncate">
                            <a
                                href={`${splash.meet_team_dashboard_url}?${window.location.search}`}
                            >
                                <img
                                    className="c-table-clean__img u-mr--"
                                    src={
                                        splash.team
                                            ? splash.team.small_logo_url
                                            : "/media/img/icon-fa-institution.png"
                                    }
                                    alt={`${splash.team ? splash.team.short_name : "Unknown team"} logo`}
                                />{" "}
                                <span className="hidden-xs">
                                    {splash.team
                                        ? splash.team.short_name
                                        : "Unknown"}
                                </span>
                            </a>
                        </td>
                        <td>{splash.event_display}</td>
                        <td className="u-text-end u-text-semi">
                            <a href={splash.absolute_url}>{splash.toSwim}</a>
                        </td>
                        <td className="u-text-end">
                            {splash.get_pointsscored
                                ? Number(splash.get_pointsscored).toFixed()
                                : "\u2013"}
                        </td>
                        {pointsystem === "FINA" ? (
                            <td className="u-text-end">
                                {splash.fina_points
                                    ? Number(splash.fina_points).toFixed()
                                    : "\u2013"}
                            </td>
                        ) : (
                            <td className="u-text-end">
                                {splash.pointvalue
                                    ? Number(splash.pointvalue).toFixed()
                                    : "\u2013"}
                            </td>
                        )}
                    </tr>
                ))}
            </tbody>
        );
    }

    if (!topSwims || !topSwims.length) return null;

    return (
        <div className="c-card c-card--large u-overflow-hidden">
            <div className="c-card__item">
                <h2 className="c-title c-title--small">Performances</h2>
            </div>
            <div className="c-table-clean--responsive">
                <table className="c-table-clean c-table-clean--middle table table-hover">
                    {renderMore()}
                    {renderHead()}
                    {renderBody()}
                </table>
            </div>
        </div>
    );
}

export default TopSwims;
